import React from 'react';
import { TimerContext } from './Contexts/TimerContextProvider';
import { formatTime } from '../utils/timerUtils';
const topTimerCssClassGreen = 'text-3xl text-bold mb-2 text-green-700';
const topTimerCssClassRed = 'text-3xl text-bold mb-2 text-red-500';

const TimerDisplay = () => {
  const { time, timerPaused } = React.useContext(TimerContext);

  const { hours, minutes, seconds } = formatTime(time);
  return (
    <div id="timer">
      <div
        className={timerPaused ? topTimerCssClassRed : topTimerCssClassGreen}
      >
        {`${String(hours).padStart(2, '0')}:${String(minutes).padStart(
          2,
          '0'
        )}:${String(seconds).padStart(2, '0')}`}
      </div>
    </div>
  );
};

export default TimerDisplay;
