import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import { axiosInstance } from '../axiosApi';
import ButtonLoader from './ButtonLoader';

const SettingsModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isEmailNotif, setIsEmailNotif] = useState(false);

  useEffect(() => {
    // fetch email notif preference
    const fetchEmailPreference = async () => {
      try {
        //console.log(`user id = ${getUserId()}`);'
        // debugger;
        const response = await axiosInstance.get(
          `user/emailNotification/${localStorage.getItem('user_id')}`
        );
        setIsEmailNotif(response.data.preference);

        return response;
      } catch (error) {
        alert(`Error! ${error.message}`);
        //console.log(error);
        throw error;
      }
    };
    fetchEmailPreference();
  }, []);

  const handleUpdateEmailNotif = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.patch(
        `user/emailNotification/${localStorage.getItem('user_id')}`
      );
      setIsLoading(false);
      setIsSuccess(true);
      setIsEmailNotif(response.data.preference);
      setTimeout(() => props.closeModal(), 1000);
      return response;
    } catch (error) {
      //console.log(error);
      const res = error.response.data;
      setIsSuccess(false);
      let message = '';
      Object.keys(res).map((keyName) => {
        //console.log(keyName);// eslint-disable-line no-console
        //console.log(res[keyName]);// eslint-disable-line no-console
        if (keyName.toUpperCase() === 'DETAIL')
          message += `Error: ${res[keyName]}\n`;
        else message += `${keyName.toUpperCase()}: ${res[keyName]}\n`;
        props.renderErrorHandler(message);
        return res[keyName];
      });
    }
  };

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          // console.log(props.dialogCancelAction);
          return props.dialogCancelAction();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-500 sm:mx-0 sm:h-10 sm:w-10">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="lg:text-lg text-base font-semibold leading-6 text-gray-900"
                      >
                        Settings
                      </Dialog.Title>

                      <div class="mt-7 flex">
                        <div class="flex items-center h-5">
                          <input
                            id="helper-checkbox"
                            aria-describedby="helper-checkbox-text"
                            type="checkbox"
                            checked={isEmailNotif}
                            value=""
                            onClick={() => setIsEmailNotif(!isEmailNotif)}
                            class="w-4 h-4 text-blue-600  border-gray-300 rounded focus:ring-blue-500 focus:ring-blue-600 ring-offset-gray-800 focus:ring-2  border-gray-600"
                          />
                        </div>
                        <div class="ml-2 text-sm">
                          <label
                            for="helper-checkbox"
                            class="font-medium lg:text-base "
                          >
                            Email notifications
                          </label>
                          <p
                            id="helper-checkbox-text"
                            class="text-xs font-normal text-gray-700"
                          >
                            Receive email notifcations to update marks and exam
                            reminders.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex items-center w-full justify-center rounded-md bg-green-500 px-3 py-2 text-sm lg:text-base font-semibold text-white shadow-sm hover:bg-green-400 sm:ml-3 sm:w-auto"
                    onClick={handleUpdateEmailNotif}
                  >
                    Save
                    {isLoading && <ButtonLoader />}
                    {isSuccess && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="ml-1 w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    )}
                  </button>

                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm lg:text-base font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => props.closeModal()}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SettingsModal;
