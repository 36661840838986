import {
  DELETE_COURSE_FROM_SEMESTER,
  DELETE_SEMESTER,
  SET_SEMESTERS,
  CLEAR_SEMESTER,
  UPDATE_LETTER_GRADE_OF_COURSE,
  ADD_COURSE_TO_SEMESTER,
} from '../actions/semesterActions';

const initialState = {
  semesters: [],
};

const semesterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEMESTERS:
      return {
        ...state,
        semesters: action.payload,
      };
    case CLEAR_SEMESTER:
      return {
        semesters: [],
      };
    case DELETE_SEMESTER: {
      const { semesterId } = action.payload;

      const updatedSemesters = state.semesters.filter(
        (semester) => semester.uuid !== semesterId
      );

      return {
        ...state,
        semesters: updatedSemesters,
      };
    }
    case UPDATE_LETTER_GRADE_OF_COURSE: {
      const { course, semesterUUID, grade } = action.payload;
      const updatedSemesters = state.semesters.map((sem) => {
        if (sem.uuid === semesterUUID) {
          const updatedCourses = sem.courses.filter(
            (crs) => crs.uuid !== course.uuid
          );
          let targetCourse = sem.courses.filter(
            (crs) => crs.uuid === course.uuid
          )[0];

          const targetCourseCopy = { ...targetCourse, letterGrade: grade };
          updatedCourses.push(targetCourseCopy);

          return {
            ...sem,
            courses: updatedCourses,
          };
        } else {
          return sem;
        }
      });
      return {
        ...state,
        semesters: updatedSemesters,
      };
    }
    case DELETE_COURSE_FROM_SEMESTER: {
      const { course, semesterUUID } = action.payload;
      const updatedSemesters = state.semesters.map((sem) => {
        if (sem.uuid === semesterUUID) {
          const updatedCourses = sem.courses.filter(
            (crs) => crs.uuid !== course.uuid
          );
          return {
            ...sem,
            courses: updatedCourses,
          };
        } else {
          return sem;
        }
      });

      return {
        ...state,
        semesters: updatedSemesters,
      };
    }
    case ADD_COURSE_TO_SEMESTER: {
      const { course, semesterUUID } = action.payload;

      const updatedSemesters = state.semesters.map((sem) => {
        if (sem.uuid === semesterUUID) {
          let updatedCourses = sem.courses.slice();
          updatedCourses.map((crs) => {
            if (crs.uuid === course.uuid) {
              course.letterGrade = crs.letterGrade;
              return course;
            } else {
              return crs;
            }
          });

          return {
            ...sem,
            courses: updatedCourses,
          };
        } else {
          return sem;
        }
      });

      return {
        ...state,
        semesters: updatedSemesters,
      };
    }
    default:
      return state;
  }
};

export default semesterReducer;
