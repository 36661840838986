import NewNavBar from './NewNavBar';
import { useHistory } from 'react-router-dom';
import { axiosInstance } from '../axiosApi';
import React, { useState, useEffect } from 'react';

const DailyStreaks = () => {
  const [currentStreak, setCurrentStreak] = useState(0);
  const [highestStreak, setHighestStreak] = useState(0);

  useEffect(() => {
    fetchDailyStreaks();
  }, []);

  const getUserId = () => localStorage.getItem('user_id');
  const fetchDailyStreaks = async () => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await axiosInstance.get(`streaks/`, {
      params: {
        user_id: getUserId(),
        timezone: userTimeZone,
      },
    });

    setCurrentStreak(response.data.daily);
    setHighestStreak(response.data.highest);
  };

  return (
    <div
      href="#"
      class="flex flex-col sm:flex-row-full ml-2 p-6  border border-gray-200 rounded-lg shadow hover:bg-gray-100 "
    >
      <h5 class="mb-2 text-center text-sm sm:text-lg font-bold  text-gray-900 ">
        Streaks
      </h5>
      <p className="mt-1 truncate text-xs sm:leading-5 text-gray-500">
        Study for atleast <strong>30 mins</strong> each day to maintain streaks!
      </p>
      <div className="flex justify-between">
        <div
          href="#"
          class="mt-2 block w-1/2 p-6  ml-2 border border-gray-200 rounded-lg shadow hover:bg-gray-100 "
        >
          <h5 class="text-center mb-2  text-sm sm:text-base font-bold  text-gray-900 ">
            Daily
          </h5>
          <div class="flex justify-center text-gray-700 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="darkorange"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="inline w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.362 5.214A8.252 8.252 0 0112 21 8.25 8.25 0 016.038 7.048 8.287 8.287 0 009 9.6a8.983 8.983 0 013.361-6.867 8.21 8.21 0 003 2.48z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 18a3.75 3.75 0 00.495-7.467 5.99 5.99 0 00-1.925 3.546 5.974 5.974 0 01-2.133-1A3.75 3.75 0 0012 18z"
              />
            </svg>
            <span className="font-bold text-sm ml-1">{currentStreak}</span>
          </div>
        </div>

        <div
          href="#"
          class="mt-2 block w-1/2 p-6  border border-gray-200 rounded-lg shadow hover:bg-gray-100 ml-2 "
        >
          <h5 class="text-center mb-2  text-sm sm:text-base font-bold  text-gray-900 ">
            Highest
          </h5>
          <div class="flex justify-center font-normal text-gray-700 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="darkorange"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="inline w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.362 5.214A8.252 8.252 0 0112 21 8.25 8.25 0 016.038 7.048 8.287 8.287 0 009 9.6a8.983 8.983 0 013.361-6.867 8.21 8.21 0 003 2.48z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 18a3.75 3.75 0 00.495-7.467 5.99 5.99 0 00-1.925 3.546 5.974 5.974 0 01-2.133-1A3.75 3.75 0 0012 18z"
              />
            </svg>
            <span className="font-bold text-sm ml-1">{highestStreak}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyStreaks;
