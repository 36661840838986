/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { useHistory, Route, Redirect } from 'react-router-dom';
// component use to require authentication
// for routes and make them role-based.
function ProtectedRoute({ component: Component, ...rest }) {
  const accessToken = localStorage.getItem('access_token');
  const history = useHistory();
  // debugger;
  // debugger;
  return (
    <Route
      {...rest}
      render={(props) => {
        if (accessToken) {
          // debugger;
          return <Component {...[props]} />;
        } else {
          // debugger;
          return history.push('/signin');
        }
      }}
    />
  );
  // if (accessToken)// if there is a logged in use
  //   	history.push('signin');
  // else
  //   return (<Outlet />); // user is authorized to view the page
}

export default ProtectedRoute;
