import {
  SET_CUSTOM_GRADE_SETTINGS,
  CLEAR_CUSTOM_GRADE_SETTINGS,
  SET_CUSTOM_GRADE_SETTINGS_OBJ,
} from '../actions/customGradeSettingsActions';

const initialState = { customGradeSettings: [], customGradeSettingsObj: {} };

const customGradeSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOM_GRADE_SETTINGS: {
      const { customGradeSettings } = action.payload;

      const sortedCustomGradeSettings = customGradeSettings.sort(function (
        a,
        b
      ) {
        return b[1] - a[1];
      });

      return {
        ...state,
        customGradeSettings: sortedCustomGradeSettings,
      };
    }
    case SET_CUSTOM_GRADE_SETTINGS_OBJ: {
      const { customGradeSettingsObj } = action.payload;

      return {
        ...state,
        customGradeSettingsObj: customGradeSettingsObj,
      };
    }
    case CLEAR_CUSTOM_GRADE_SETTINGS: {
      return {};
    }
    default:
      return state;
  }
};

export default customGradeSettingsReducer;
