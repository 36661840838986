import NewNavBar from './NewNavBar';
import { useHistory } from 'react-router-dom';
import { axiosInstance } from '../axiosApi';
import React, { useState, useEffect } from 'react';

const UpcomingTasks = () => {
  const history = useHistory();
  const getUserId = () => localStorage.getItem('user_id');

  const [thisWeekTasks, setThisWeekTasks] = useState([]);
  const [upComingWeekTasks, setUpcomingWeekTasks] = useState([]);
  const [nextWeek, setNextWeek] = useState([]);

  const fetchUpcomingTasks = async () => {
    try {
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await axiosInstance.get(`upcoming/${getUserId()}`, {
        params: {
          timezone: userTimeZone,
        },
      });
      response.data.currentWeek.sort((a, b) => a.daysLeft - b.daysLeft);
      response.data.futureWeek.sort((a, b) => a.daysLeft - b.daysLeft);
      response.data.nextWeek.sort((a, b) => a.daysLeft - b.daysLeft);
      setThisWeekTasks(response.data.currentWeek);
      setNextWeek(response.data.nextWeek);
      setUpcomingWeekTasks(response.data.futureWeek);

      return response;
    } catch (error) {
      alert(`Error! ${error.message}`);
      //console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    if (thisWeekTasks.length === 0 && upComingWeekTasks.length === 0) {
      fetchUpcomingTasks();
    }

    document.title = 'Upcoming tasks | Course Tracker';
  }, []);

  const formatDate = (dateStr) => {
    // Create a Date object from the string and assume it's in UTC
    const date = new Date(dateStr);

    // Define options for formatting the date in UTC
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZone: 'UTC', // Specify the time zone as UTC
    };

    // Format the date using Intl.DateTimeFormat
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(
      date
    );

    // Get the abbreviated day of the week (e.g., "Mon")
    const dayOfWeek = date.toLocaleDateString('en-US', {
      weekday: 'short',
      timeZone: 'UTC', // Specify the time zone as UTC
    });

    // Combine the formatted date and day of the week
    const formattedDateWithDay = `${dayOfWeek}, ${formattedDate}`;

    return formattedDateWithDay;
  };

  const influencesFinalGrade = (component) => {
    return component.weight >= 10;
  };

  const findPriority = (component) => {
    const highPriority = (
      <span className="ml-2 inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
        High priority
      </span>
    );

    const mediumPriority = (
      <span class="ml-2 inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10">
        Medium priority
      </span>
    );

    const lowPriority = (
      <span className="ml-2 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
        Low priority
      </span>
    );

    if (component.daysLeft <= 3) {
      return highPriority;
    } else if (component.daysLeft > 3 && component.daysLeft <= 10) {
      return mediumPriority;
    } else {
      return lowPriority;
    }
  };

  const influencesFinalGradeBadge = (
    <span class="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 ml-1  text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
      Grade altering
    </span>
  );
  return (
    <>
      <NewNavBar handleGoBack={() => history.goBack()} />

      <div className="ml-4">
        <div className="mt-10 ml-5 mr-10">
          <h2 className=" py-4 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            Upcoming Tasks
          </h2>
          <p className="mt-1 truncate text-xs sm:leading-5 text-gray-500">
            To view upcoming tasks, add components for your courses!
          </p>
          <h2 className="mt-10 text-lg font-semibold leading-7 text-gray-900 sm:truncate md:text-2xl sm:tracking-tight">
            This week
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="tomato"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 inline ml-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
              />
            </svg>
          </h2>
          <div className="mt-4 ">
            <ul role="list" className="divide-y divide-gray-100 ">
              {thisWeekTasks.map((component) => (
                <li
                  key={component.course}
                  className="flex justify-between gap-x-6 py-5 px-2 hover:cursor-pointer rounded-lg transition duration-300 ease-in-out hover:bg-gray-200 hover:border-gray-400"
                  onClick={() =>
                    history.push(`/courseDetails/${component.course.uuid}`)
                  }
                >
                  <div className="flex min-w-0 gap-x-4">
                    <div className="min-w-0 flex-auto">
                      <p className="text-base font-semibold leading-6 text-gray-900">
                        {component.name}

                        {findPriority(component)}
                        {influencesFinalGrade(component) &&
                          influencesFinalGradeBadge}
                      </p>

                      <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                        {component?.course?.name}
                      </p>
                    </div>
                  </div>
                  <div className=" shrink-0 sm:flex sm:flex-col sm:items-end">
                    <p className="text-lg font-semibold leading-6 text-gray-900">
                      <span className="text-red-600">
                        {component.daysLeft === 0 && 'Today'}
                        {component.daysLeft === 1 && 'Tomorrow'}
                      </span>

                      {component.daysLeft > 1 && (
                        <span>
                          {component.daysLeft}{' '}
                          {component.daysLeft === 1 ? 'day left' : 'days left'}
                        </span>
                      )}
                    </p>
                    <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                      {formatDate(component.due_date)}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
            {thisWeekTasks.length === 0 && (
              <p>No tasks due in the current week.</p>
            )}
          </div>

          {/* Next Week */}

          <h2 className="mt-10 text-lg font-semibold leading-7 text-gray-900 sm:truncate md:text-2xl sm:tracking-tight">
            Next week
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="greenyellow"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="inline ml-2 w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
              />
            </svg>
          </h2>
          <div className="mt-4">
            <ul role="list" className="divide-y divide-gray-100">
              {nextWeek.map((nextWeek) => (
                <li
                  key={nextWeek.course}
                  className="flex justify-between gap-x-6 py-5 px-2 hover:cursor-pointer rounded-lg transition duration-300 ease-in-out hover:bg-gray-200 hover:border-gray-400"
                  onClick={() =>
                    history.push(`/courseDetails/${nextWeek.course.uuid}`)
                  }
                >
                  <div className="flex min-w-0 gap-x-4">
                    <div className="min-w-0 flex-auto">
                      <p className="text-base font-semibold leading-6 text-gray-900">
                        {nextWeek.name}

                        {findPriority(nextWeek)}
                        {influencesFinalGrade(nextWeek) &&
                          influencesFinalGradeBadge}
                      </p>

                      <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                        {nextWeek.course.name}
                      </p>
                    </div>
                  </div>
                  <div className=" shrink-0 sm:flex sm:flex-col sm:items-end">
                    <p className="text-lg font-semibold leading-6 text-gray-900">
                      {nextWeek.daysLeft}{' '}
                      {nextWeek.daysLeft === 1 ? 'day left' : 'days left'}
                    </p>
                    <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                      {formatDate(nextWeek.due_date)}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
            {nextWeek.length === 0 && <p>No tasks due in the next week.</p>}
          </div>

          {/* future */}
          <h2 className="mt-10 text-lg font-semibold leading-7 text-gray-900 sm:truncate md:text-2xl sm:tracking-tight">
            Future
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="gold"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 inline ml-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
              />
            </svg>
          </h2>
          <div className="mt-4">
            <ul role="list" className="divide-y divide-gray-100">
              {upComingWeekTasks.map((ftr) => (
                <li
                  key={ftr.course}
                  className="flex justify-between gap-x-6 py-5 px-2 hover:cursor-pointer rounded-lg transition duration-300 ease-in-out hover:bg-gray-200 hover:border-gray-400"
                  onClick={() =>
                    history.push(`/courseDetails/${ftr.course.uuid}`)
                  }
                >
                  <div className="flex min-w-0 gap-x-4">
                    <div className="min-w-0 flex-auto">
                      <p className="text-base font-semibold leading-6 text-gray-900">
                        {ftr.name}
                        {findPriority(ftr)}
                        {influencesFinalGrade(ftr) && influencesFinalGradeBadge}
                      </p>

                      <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                        {ftr.course.name}
                      </p>
                    </div>
                  </div>
                  <div className=" shrink-0 sm:flex sm:flex-col sm:items-end">
                    <p className="text-lg font-semibold leading-6 text-gray-900">
                      {ftr.daysLeft}{' '}
                      {ftr.daysLeft === 1 ? 'day left' : 'days left'}
                    </p>
                    <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                      {formatDate(ftr.due_date)}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
            {upComingWeekTasks.length === 0 && (
              <p>No tasks due in the upcoming weeks.</p>
            )}
          </div>
          <div id="btn-group" className="mt-10 mb-10 flex justify-end">
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              onClick={() => history.push('/home')}
            >
              Go home
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpcomingTasks;
