import React, { createContext, useContext, useState } from 'react';

const AuthAlertContext = createContext();

export const AuthAlertProvider = ({ children }) => {
  const [showSignupSuccessAlert, setShowSignupSuccessAlert] = useState(false);
  const [showEmailVerSuccessAlert, setShowEmailVerSuccessAlert] =
    useState(false);

  const setSignupSuccessAlert = () => {
    setShowSignupSuccessAlert(true);
  };

  const closeSignUpSuccessAlert = () => {
    setShowSignupSuccessAlert(false);
  };

  const setEmailVerSuccessAlert = () => {
    setShowEmailVerSuccessAlert(true);
  };

  const closeEmailVerSuccessAlert = () => {
    setShowEmailVerSuccessAlert(false);
  };

  return (
    <AuthAlertContext.Provider
      value={{
        showEmailVerSuccessAlert,
        showSignupSuccessAlert,
        setSignupSuccessAlert,
        setEmailVerSuccessAlert,
        closeSignUpSuccessAlert,
        closeEmailVerSuccessAlert,
      }}
    >
      {children}
    </AuthAlertContext.Provider>
  );
};

export const useAuthAlert = () => {
  return useContext(AuthAlertContext);
};
