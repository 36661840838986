const stats = [
  { id: 1, name: 'Used By', value: '1000s' },
  { id: 2, name: 'Assets under holding', value: '$119 trillion' },
  { id: 3, name: 'New users annually', value: '46,000' },
];

export default function Mission() {
  return (
    <div className="overflow-hidden bg-white py-5 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="bg-white ">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">
            Mission
          </h2>
          <p className="mt-2 text-2xl sm:text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Why Course Tracker
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="yellow"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="darkgoldenrod"
              className="ml-2 inline h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
              />
            </svg>
          </p>
          <p className="mt-6 text-base sm:text-lg leading-8 text-gray-600">
            As a student, I often found myself reaching for a calculator after
            exams, trying to figure out the minimum scores I needed to reach my
            goal grades. This repetitive task made me realize the need for a
            better way to manage my courses.
          </p>

          <p className="mt-6 text-base sm:text-lg leading-8 text-gray-600">
            I created Course Tracker to eliminate this redundancy. Initially, it
            was a simple tool to input my marks and let the computer calculate
            my expected scores. As I used the app, I continued to add features
            that I found helpful. For example, I built a study timer to track
            the time I spent on each course, and then added a stats page with
            graphical data to provide meaningful insights. To keep myself
            motivated, I introduced a streaks feature to encourage daily study
            habits.
          </p>

          <p className="mt-6 text-base sm:text-lg leading-8 text-gray-600">
            Over time, Course Tracker evolved with more features. Although I
            have since graduated, I am proud that what started as a small
            personal project has grown to help students worldwide. Course
            Tracker was instrumental in my undergraduate journey, and I am
            committed to its ongoing development.
          </p>

          <p className="mt-6 text-base sm:text-lg leading-8 text-gray-600">
            Fellow students, if you ever think, "I wish this app had this
            feature," or simply want to let me know that you enjoy using it,
            please don't hesitate to share your thoughts. You can provide
            feedback through the app's feedback section or reach out directly at{' '}
            <a
              href="mailto:coursetrackeredu@gmail.com"
              className="font-bold text-pink-500 underline hover:no-underline cursor-pointer"
            >
              coursetrackeredu@gmail.com
            </a>
            . Your input helps make Course Tracker even better. Thank you! :)
          </p>
        </div>
      </div>
    </div>
  );
}
