export const ADD_COURSE = 'ADD_COURSE';
export const EDIT_COURSE = 'EDIT_COURSE';
export const DELETE_COURSE = 'DELETE_COURSE';
export const SET_COURSES = 'SET_COURSES';
export const RESET_COURSES = 'RESET_COURSES';
export const CLEAR_COURSES = 'CLEAR_COURSES';
export const SET_COURSE_LETTER_GRADE = 'SET_COURSE_LETTER_GRADE';

export const setCourses = (semester) => ({
  type: SET_COURSES,
  payload: { semester },
});

export const addCourse = (course, semesterId) => ({
  type: ADD_COURSE,
  payload: {
    course,
    semesterId,
  },
});

export const editCourse = (course, semesterId) => ({
  type: EDIT_COURSE,
  payload: {
    course,
    semesterId,
  },
});

export const removeCourse = (course, semesterId) => ({
  type: DELETE_COURSE,
  payload: {
    course,
    semesterId,
  },
});

export const setCourseLetterGrade = (semesterId, course, grade) => ({
  type: SET_COURSE_LETTER_GRADE,
  payload: {
    semesterId,
    course,
    grade,
  },
});

export const resetCourses = (semesterId) => ({
  type: RESET_COURSES,
  payload: {
    semesterId,
  },
});

export const clearCourses = () => ({
  type: CLEAR_COURSES,
});
