import React, { useEffect, useState } from 'react';
import StatsDatePicker from './StatsDatePicker';
import { axiosInstance } from '../axiosApi';

function TimerHistoryTable() {
  const [dayStats, setDayStats] = useState([]);

  const calcTotalTimeStudied = () => {
    let totalTimeInSecs = 0;
    for (let stat of dayStats) {
      totalTimeInSecs += stat.time_studied;
    }

    const totalHours = Math.floor(totalTimeInSecs / 3600);
    const totalMins = Math.floor((totalTimeInSecs % 3600) / 60);
    const totalSeconds = Math.floor(totalTimeInSecs % 60);

    let formattedTime = '';

    if (totalHours > 0) {
      formattedTime += `${totalHours} Hr `;
    }

    if (totalMins > 0) {
      formattedTime += `${totalMins} min `;
    }

    if (totalSeconds > 0 || formattedTime === '') {
      formattedTime += `${totalSeconds} secs`;
    }

    return formattedTime.trim();
    // return `${String(totalHours).padStart(2, '0')}
    // :${String(totalMins).padStart(2, '0')}
    // :${String(totalSeconds).padStart(2, '0')}`;
  };
  const getUserId = () => localStorage.getItem('user_id');
  const fetchStatsForDay = async (selectedDate) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setDayStats([]);

    const response = await axiosInstance.get(`stats/`, {
      params: {
        user_id: getUserId(),
        start_date: selectedDate.toISOString(),
        end_date: selectedDate.toISOString(),
        timezone: userTimeZone,
      },
    });

    const dailyStats = response.data.courseStats;

    if (Object.values(dailyStats)[0].length === 0) {
      return;
    }
    for (const stats of Object.values(dailyStats)) {
      if (!stats) return;

      for (const stat of stats) {
        setDayStats((prevStats) => [...prevStats, stat]);
      }
    }
  };

  const formatTime = (timeStudiedInSec) => {
    const totalHours = Math.floor(timeStudiedInSec / 3600);
    const totalMins = Math.floor((timeStudiedInSec % 3600) / 60);
    const totalSeconds = Math.floor(timeStudiedInSec % 60);

    let formattedTime = '';

    if (totalHours > 0) {
      formattedTime += `${totalHours} Hr `;
    }

    if (totalMins > 0) {
      formattedTime += `${totalMins} min `;
    }

    if (totalSeconds > 0 || formattedTime === '') {
      formattedTime += `${totalSeconds} secs`;
    }

    return formattedTime.trim();
    // return `${String(totalHours).padStart(2, '0')}
    // :${String(totalMins).padStart(2, '0')}
    // :${String(totalSeconds).padStart(2, '0')}`;
  };

  return (
    <div className="px-10">
      <h1 className="flex justify-start w-full py-4 text-lg font-semibold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
        Study History
      </h1>
      <StatsDatePicker fetchStatsForDay={fetchStatsForDay} />
      <div className="  mb-10  sm:mb-20 relative overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3">
                Course
              </th>
              <th scope="col" className="px-6 py-3">
                Component
              </th>
              <th scope="col" className="px-6 py-3">
                Description
              </th>
              <th scope="col" className="px-6 py-3">
                Time Studied
              </th>
            </tr>
          </thead>
          <tbody>
            {dayStats.map((dayStat) => (
              <tr key={dayStat.uuid} className="bg-white border-b">
                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                  {dayStat.course.name ? dayStat.course.name : '-'}
                </td>
                <td className="px-6 py-4">
                  {dayStat.component.name ? dayStat.component.name : '-'}
                </td>
                <td className="px-6 py-4">
                  {dayStat.description ? dayStat.description : '-'}
                </td>
                <td className="px-6 py-4">
                  {formatTime(dayStat.time_studied)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {dayStats.length > 0 && (
          <h1 className="flex justify-start mt-10 ">
            <strong style={{ whiteSpace: 'pre' }}>Total time studied: </strong>
            {'   ' + calcTotalTimeStudied()}
          </h1>
        )}
        {dayStats.length === 0 && (
          <h1 className="flex font-bold justify-start sm:justify-center mt-7 sm:mt-10 text-xs sm:text-sm">
            {' '}
            No stats for this given day
          </h1>
        )}
      </div>
    </div>
  );
}

export default React.memo(TimerHistoryTable);
