import React, { useEffect, useState } from 'react';
import { useHistory, Prompt } from 'react-router-dom';
import StudyDescriptionDialog from './StudyDescriptionDialog';
import TimerDisplay from './TimerDisplay';
import TimerHistoryTable from './TimerHistoryTable';
import NewNavBar from './NewNavBar';
import { TimerContext } from './Contexts/TimerContextProvider';
import TimerActions from './TimerActions';
import { formatTime } from '../utils/timerUtils';
import Badge from './Badge';
import Toast from './Toast';

const TimerPage = () => {
  const history = useHistory();
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showDescriptionModal, setShowDescriptionModal] = React.useState(false);
  const {
    time,
    timerOn,
    timerPaused,
    stopTimer,
    forCourse,
    forComponent,
    description,
    showStreaksNotif,
  } = React.useContext(TimerContext);

  useEffect(() => {
    const { hours, minutes, seconds } = formatTime(time);

    if (timerOn) {
      document.title = `${String(hours).padStart(2, '0')}:${String(
        minutes
      ).padStart(2, '0')}
    :${String(seconds).padStart(2, '0')} - Timer | Course Tracker`;
    } else {
      document.title = `Timer | Course Tracker`;
    }
  }, [time, timerOn]);

  const addTime = async () => {
    try {
      await stopTimer();
    } catch (error) {
      alert(`Error! ${error.message}`);
      throw error;
    }
    setShowSuccessToast(true);
    setInterval(() => {
      setShowSuccessToast(false);
    }, 3000);
  };

  const activeTimer = timerOn || timerPaused;
  const badgeText = () => {
    let text = '';
    if (description.trim()) {
      text += `${description} - `;
    }
    if (forCourse && Object.keys(forCourse).length > 0) {
      text += `${forCourse.name}`;

      if (forComponent && Object.keys(forComponent).length > 0) {
        text += ` - ${forComponent.name}`;
      }
    }

    return text;
  };
  return (
    <div>
      <NewNavBar handleGoBack={() => history.goBack()} />
      {!showStreaksNotif && showSuccessToast && (
        <Toast text="Time added to stats" />
      )}
      {showStreaksNotif && <Toast text="Great stuff! Streaks 🔥 +1" />}
      {showDescriptionModal && (
        <StudyDescriptionDialog
          closeModal={() => {
            setShowDescriptionModal(false);
          }}
        />
      )}
      <div className="mb-10 flex flex-col items-center justify-center">
        <div className="flex  items-center">
          <TimerDisplay />
          <TimerActions
            addTime={addTime}
            setupTimer={() => setShowDescriptionModal(true)}
          />
        </div>

        {activeTimer && badgeText() && (
          <div className="max-w-[250px]">
            <Badge
              text={badgeText()}
              color={timerOn ? 'green' : timerPaused ? 'red' : ''}
            />
          </div>
        )}
      </div>

      <TimerHistoryTable />
      <Prompt
        when={timerOn || timerPaused}
        message="You have an active timer. Are you sure you want to leave this page?"
      />
    </div>
  );
};

export default TimerPage;
