export const calcTotalWeightCompleted = (components = {}) => {
  let sum = 0;
  if (!components) {
    return sum;
  }
  for (let component of components) {
    if (component.grade_received < 0) continue;
    sum += component.weight;
  }
  return sum.toFixed(2);
};

export const calcTotalGoalGradeTillNow = (components = {}) => {
  let goalGrade = 0;
  if (!components) {
    return goalGrade;
  }
  for (let component of components) {
    if (component.goal_grade < 0 || component.grade_received < 0) continue;
    goalGrade += (component.weight / 100) * (component.goal_grade / 100);
  }
  return goalGrade * 100;
};

export const calcGradeReceived = (components = {}) => {
  let gradeReceived = 0;
  if (!components) {
    return gradeReceived;
  }
  for (let component of components) {
    if (component.grade_received < 0) continue;
    gradeReceived +=
      (component.weight / 100) * (component.grade_received / 100);
  }
  return gradeReceived * 100;
};

export const calcGoalGrade = (components = {}) => {
  let goalGrade = 0;
  if (!components) {
    return goalGrade;
  }
  for (let component of components) {
    if (component.goal_grade < 0) continue;
    goalGrade += (component.weight / 100) * (component.goal_grade / 100);
  }
  return goalGrade * 100;
};

export const calcWeight = (components = {}) => {
  let sum = 0;
  if (!components) {
    return sum;
  }
  for (let component of components) {
    sum += component.weight;
  }
  return parseFloat(sum.toFixed(2));
};

export const nameAlreadyExists = (name, components) => {
  if (!name) return;
  if (name === '' || name === '') return;
  name = name.toLowerCase();

  for (let component of components) {
    if (component.name.toLowerCase() === name) return true;
  }
  return false;
};
