import NewNavBar from './NewNavBar';
import { useHistory } from 'react-router-dom';
import { axiosInstance } from '../axiosApi';
import React, { useState, useEffect } from 'react';
import { PieChart } from './PieChart';
import TrajectoryGraph from './TrajectoryGraph';
import GraphicalStats from './GraphicalStats';
import OtherStats from './OtherStats';

const Stats = () => {
  const history = useHistory();
  const getUserId = () => localStorage.getItem('user_id');

  const [thisWeekTasks, setThisWeekTasks] = useState([]);
  const [upComingWeekTasks, setUpcomingWeekTasks] = useState([]);
  const [nextWeek, setNextWeek] = useState([]);

  useEffect(() => {
    document.title = 'Stats | Course Tracker';
  }, []);

  return (
    <div>
      <NewNavBar handleGoBack={() => history.goBack()} />
      <h1 className="flex justify-center  mt-5 py-4 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
        Stats
      </h1>
      <div className="flex flex-col justify-between xl:flex-row xl:items-start xl:justify-evenly items-center mt-10 xl:mt-20 xl:mb-10 xl:mb-20">
        <GraphicalStats />
        <OtherStats />
      </div>
    </div>
  );
};

export default Stats;
