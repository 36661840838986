import { Button, IconButton } from '@mui/material';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { axiosInstance } from '../axiosApi';
import React, { useState } from 'react';
import Modal from './Modal';
import FeedbackModal from './FeedbackModal';
import { useDispatch } from 'react-redux';
import Alert from '@mui/material/Alert';
import SettingsModal from './SettingsModal';
import { clearCourses } from '../redux/actions/courseActions';
import { clearSemesters } from '../redux/actions/semesterActions';
import { clearCustomGradeSettings } from '../redux/actions/customGradeSettingsActions';
import Banner from './Banner';
const NewNavBar = ({ noBanner }) => {
  const [showSignOutModal, setShowSignOutModal] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showFeedbackAlert, setShowFeedbackAlert] = useState(false);
  const [showSettingsDropdown, setshowSettingsDropdown] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname.slice(1).toLowerCase(); // Remove leading slash

  const accessToken = localStorage.getItem('access_token');
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const selectedStyle = 'bg-gray-700 text-white hover:text-gray-700';
  const closeSignoutModal = () => setShowSignOutModal(false);
  const closeFeedbackModal = () => setShowFeedbackModal(false);
  const closeSettingsModal = () => setshowSettingsDropdown(false);
  const closeSuccessHandler = () => setShowFeedbackAlert(false);
  // const closeTimerDisclaimer = () => setShowTimerDisclaimer(false);

  const dispatch = useDispatch();

  const goToSettingsPage = () => {
    history.push('/settings');
  };

  const goToStatsPage = () => {
    history.push('/stats');
  };

  const goToTimerPage = () => {
    history.push('/timer');
  };

  const goToUpcomingTasks = () => {
    history.push('/upcoming');
  };

  const openFeedbackAlert = () => {
    setShowFeedbackAlert(true);
    setTimeout(() => {
      setShowFeedbackAlert(false);
    }, 3000);
  };
  const handleSignout = () => {
    setShowSignOutModal(false);
    axiosInstance
      .post('/user/logout/', {
        refresh_token: localStorage.getItem('refresh_token'),
      })
      .then((response) => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        axiosInstance.defaults.headers.Authorization = null;
        history.push('/');
        //console.log(response);
        localStorage.clear();
        dispatch(clearCourses());
        dispatch(clearSemesters());
        dispatch(clearCustomGradeSettings());

        return response;
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          //console.log(error.response.data);// eslint-disable-line no-console
          const res = error.response.data;

          Object.keys(res).map((keyName) => {
            return res[keyName];
          });

          //console.log(error.response.status);// eslint-disable-line no-console
          //console.log(error.response.headers);// eslint-disable-line no-console
        } else if (error.request) {
          // The request was made but no response was received
          //console.log(error.request);// eslint-disable-line no-console
        } else {
          // Something happened in setting up the request that triggered an Error
          //console.log('Error', error.message);// eslint-disable-line no-console
        }
      });
  };

  const accessStyle =
    'relative w-full flex h-16 items-center sm:justify-between';
  const noAccessStyle =
    'relative w-full flex h-16 items-center justify-between';
  return (
    <div>
      {!noBanner && <Banner />}
      <nav class="  px-1 sm:px-2 lg:px-2 lg:py-2 mb-4 sm:mb-10">
        <div class={accessToken ? accessStyle : noAccessStyle}>
          {accessToken && (
            <div class="absolute inset-y-0 right-0 flex items-center md:hidden">
              <button
                type="button"
                class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
                onClick={toggleMenu}
              >
                <span class="absolute -inset-0.5"></span>
                <span class="sr-only">Open main menu</span>

                <svg
                  class="block h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>

                <svg
                  class="hidden h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          )}
          <div class="flex  items-center ">
            <Link to="/home" className="flex items-center ">
              <img
                src="/courseTrackerIcon.png"
                className=" h-7 sm:h-12 lg:h-12"
                alt="CourseTracker Logo"
              />
              <span className=" text-gray-700 text-base sm:text-lg md:text-xl lg:text-2xl whitespace-nowrap">
                Course Tracker
              </span>
            </Link>
          </div>

          {!accessToken && (
            <button
              type="button"
              className="text-gray-700 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm md:text-sm lg:text-lg font-small"
              onClick={() => history.push('/signin')}
            >
              Log in →
            </button>
          )}

          {accessToken && (
            <div class=" hidden md:flex sm:justify-normal sm:items-center sm:ml-12">
              <button
                href="#"
                className={`${
                  currentPath === 'home' ? selectedStyle : ''
                } text-gray-700 hover:bg-gray-300 rounded-md px-3 py-2 text-xs  md:text-sm lg:text-lg font-small`}
                aria-current="page"
                onClick={() => history.push('/home')}
              >
                Home
              </button>
              <button
                href="#"
                className={`${
                  currentPath === 'timer' ? selectedStyle : ''
                } text-gray-700 hover:bg-gray-300 rounded-md px-3 py-2 text-xs  md:text-sm lg:text-lg font-small`}
                aria-current="page"
                onClick={goToTimerPage}
              >
                Timer
              </button>
              <button
                className={`${
                  currentPath === 'stats' ? selectedStyle : ''
                } text-gray-700 hover:bg-gray-300 rounded-md px-3 py-2 text-xs  md:text-sm lg:text-lg font-small`}
                onClick={goToStatsPage}
              >
                Stats
                {/* <span class="ml-1 inline-flex items-center rounded-md bg-indigo-50 px-1 text-xs font-small text-indigo-700 ring-1 ring-inset ring-indigo-700/10 whitespace-nowrap">
                  NEW
                </span> */}
              </button>
              <button
                className={`${
                  currentPath === 'upcoming' ? selectedStyle : ''
                } text-gray-700 hover:bg-gray-300  rounded-md px-3 py-2 text-xs  md:text-sm lg:text-lg font-small`}
                onClick={goToUpcomingTasks}
              >
                Upcoming tasks
              </button>
            </div>
          )}
          {accessToken && (
            <div class="hidden md:flex sm:justify-normal  ">
              <button
                type="button"
                class="text-gray-700 hover:bg-gray-300 hover:text-white rounded-md px-3 py-2 text-xs  md:text-sm lg:text-lg font-small"
                onClick={() => setShowFeedbackModal(true)}
              >
                Feedback
              </button>
              <button
                type="button"
                className={`${
                  currentPath === 'settings' ? selectedStyle : ''
                } text-gray-700 hover:bg-gray-300  rounded-md px-3 py-2 text-xs  md:text-sm lg:text-lg font-small`}
                onClick={goToSettingsPage}
              >
                Settings
              </button>
              <button
                type="button"
                class="text-gray-700  hover:bg-gray-300 hover:text-white rounded-md px-3 py-2 text-xs  md:text-sm lg:text-lg font-small"
                onClick={() => setShowSignOutModal(true)}
              >
                Log out →
              </button>
            </div>
          )}
        </div>

        {isMenuOpen && (
          <div class="md:hidden" id="mobile-menu">
            {accessToken && (
              <div class="bg-gray-100 space-y-1 px-2 pb-3 pt-2">
                <a
                  href="#"
                  class=" block rounded-md px-3 py-2 text-base font-medium"
                  aria-current="page"
                  onClick={() => history.push('/home')}
                >
                  Home
                </a>
                <a
                  href="#"
                  class="hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium"
                  onClick={goToTimerPage}
                >
                  Timer
                </a>
                <a
                  href="#"
                  class="hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium"
                  onClick={goToStatsPage}
                >
                  Stats
                </a>
                <a
                  href="#"
                  class=" hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium"
                  onClick={goToUpcomingTasks}
                >
                  Upcoming Tasks
                </a>
                <a
                  href="#"
                  class=" hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium"
                  onClick={() => setShowFeedbackModal(true)}
                >
                  Feedback
                </a>
                <a
                  href="#"
                  class=" hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium"
                  onClick={goToSettingsPage}
                >
                  Settings
                </a>
                <a
                  href="#"
                  class=" hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium"
                  onClick={() => setShowSignOutModal(true)}
                >
                  Logout →
                </a>
              </div>
            )}
          </div>
        )}
      </nav>

      {showSettingsDropdown && (
        <SettingsModal closeModal={closeSettingsModal} />
      )}
      {showFeedbackModal && <FeedbackModal closeModal={closeFeedbackModal} />}
      {showSignOutModal && (
        <Modal
          dialogTitle="Log out"
          dialogMessage="Are you sure you want to log out?"
          dialogConfirmMessage="Log out"
          dialogConfirmAction={handleSignout}
          dialogCancelAction={closeSignoutModal}
        />
      )}

      {showFeedbackAlert && (
        <Alert
          style={{ width: '40%', margin: '0 auto' }}
          onClose={closeSuccessHandler}
        >
          Feedback sent successfully!
        </Alert>
      )}
    </div>
  );
};

export default NewNavBar;
