import React from 'react';
import dailyStreaks from './daily-streaks.png';

const FeatureModal = ({ closeFeatureModal }) => {
  return (
    <div
      id="defaultModal"
      tabIndex="-1"
      aria-hidden="true"
      className="fixed top-0 left-0 right-0 z-50  w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
    >
      <div className="relative w-full max-w-2xl max-h-full">
        {/* Modal content */}
        <div className="relative bg-white rounded-lg shadow ">
          {/* Modal header */}
          <div className="flex items-start justify-between p-4 border-b rounded-t ">
            <h3 className="text-xl font-semibold text-gray-900 ">
              Daily streaks and countdown timer
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center "
              data-modal-hide="defaultModal"
              onClick={closeFeatureModal}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          {/* Modal body */}
          <div className="p-6 space-y-6">
            <h1 className="text-lg font-bold">New Features: </h1>
            <ul className="text-base leading-relaxed text-gray-500 ">
              <li>
                • Daily streaks have been added as a motivation to study for
                atleast 30 mins a day. You can see your streaks on the stats
                page!
              </li>
              <li className="mt-2">
                • Countdown timer has been added, previously there was only a
                stopwatch but now you can select either.
              </li>
            </ul>
            <img src={dailyStreaks} alt="daily streaks" />
          </div>
          {/* Modal footer */}
          <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b">
            <button
              data-modal-hide="defaultModal"
              type="button"
              className="text-white bg-blue-700 hover-bg-blue-800 focus-ring-4 focus-outline-none focus-ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
              onClick={closeFeatureModal}
            >
              Okay
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureModal;
