import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { axiosInstance } from '../axiosApi';
import jwt_decode from 'jwt-decode';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import { useAuthAlert } from '../Contexts/authAlertContext';
import InfoAlert from './infoAlert';
import ButtonLoader from './ButtonLoader';

const Signin = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [emailSentSuccessMsg, setEmailSentSuccessMsg] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const {
    showSignupSuccessAlert,
    showEmailVerSuccessAlert,
    setEmailVerSuccessAlert,
    closeEmailVerSuccessAlert,
    closeSignUpSuccessAlert,
  } = useAuthAlert();

  const history = useHistory();
  const params = useParams();

  const SIGN_UP_SUCCESS_MSG = 'Signup Successful! Please verify your email!';
  const EMAIL_VERIFICATION_SUCCESS_MSG =
    'Email verification was successful! Please sign in!';
  const EMAIL_SENT_SUCCESS_MSG = 'Email sent successfully!';

  if (localStorage.getItem('access_token')) history.push('/home');
  useEffect(() => {
    if (showEmailVerSuccessAlert) {
      setTimeout(() => {
        closeEmailVerSuccessAlert();
      }, 5000);
    }

    if (showSignupSuccessAlert) {
      setTimeout(() => {
        closeSignUpSuccessAlert();
      }, 5000);
    }
    // setSignupSuccessAlert();
  }, [
    showSignupSuccessAlert,
    showEmailVerSuccessAlert,
    closeEmailVerSuccessAlert,
    closeSignUpSuccessAlert,
  ]);

  useEffect(() => {
    document.title = 'Sign in | Course Tracker';
  }, []);

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    }
  }, [errorMessage]);

  useEffect(() => {
    const verifyEmail = async () => {
      // setEmailVerifyPending(true);
      axiosInstance
        .patch('verify/email/', { token: params.token })
        .then((response) => {
          // console.log('Response Data', response.data);
          setEmailVerSuccessAlert();
          // setEmailVerificationSuccess(true);
          // setEmailVerifyPending(false);
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            // console.log('Error Response Data', error.response.data);
            const res = error.response.data;
            Object.keys(res).map((keyName) => {
              // console.log(res[keyName]);
              return res[keyName];
            });
            // console.log('Error Response Status', error.response.status);
            // console.log('Error Response Headers', error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log('Error Request', error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
        });
    };
    if (props.isEmailVerification) verifyEmail();
  }, [params.token, props.isEmailVerification]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSignin = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const response = await axiosInstance.post('token/obtain/', {
        email: email,
        password: password,
      });

      axiosInstance.defaults.headers['Authorization'] =
        'JWT ' + response.data.access;
      localStorage.setItem('access_token', response.data.access);
      localStorage.setItem('refresh_token', response.data.refresh);
      let decoded = jwt_decode(response.data.access);
      localStorage.setItem('user_id', decoded.user_id);
      setIsLoading(false);
      history.push('/home', { user_id: decoded.user_id });
      return response;
    } catch (error) {
      const res = error.response.data;
      setIsLoading(false);
      let message = '';
      Object.keys(res).map((keyName) => {
        if (keyName.toUpperCase() === 'DETAIL')
          message += `Error: ${res[keyName]}\n`;
        else message += `${keyName.toUpperCase()}: ${res[keyName]}\n`;
        return res[keyName];
      });
      setErrorMessage(message);
    }
  };

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendEmail = () => {
    const data = {
      url: `https://${window.location.hostname}/reset/password`,
      email: resetEmail.toLowerCase(),
    };

    axiosInstance
      .patch('forgot/password/', data)
      .then(() => {
        setEmailSentSuccessMsg(EMAIL_SENT_SUCCESS_MSG);
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          //console.log('here');
          //console.log('Error Response Data', error.response.data);
          const res = error.response.data;
          Object.keys(res).map((keyName) => {
            //console.log(res[keyName]);
            return res[keyName];
          });
          //console.log('Error Response Status', error.response.status);
          //console.log('Error Response Headers', error.response.headers);
        } else if (error.request) {
          //console.log('here');
          // The request was made but no response was received
          //console.log('Request Error', error.request);
        } else {
          //console.log('here');
          // Something happened in setting up the request that triggered an Error
          //console.log('Error', error.message);
        }
      });
    setOpen(false);
  };

  const handleResetEmailChange = (event) => {
    //console.log(event.target.value)
    setResetEmail(event.target.value);
  };

  return (
    <>
      <div className="w-full absolute top-5">
        {showSignupSuccessAlert && (
          <InfoAlert
            message={SIGN_UP_SUCCESS_MSG}
            close={closeSignUpSuccessAlert}
          />
        )}
        {showEmailVerSuccessAlert && (
          <InfoAlert
            message={EMAIL_VERIFICATION_SUCCESS_MSG}
            close={closeEmailVerSuccessAlert}
          />
        )}
        {emailSentSuccessMsg && (
          <InfoAlert
            message={emailSentSuccessMsg}
            close={() => setEmailSentSuccessMsg('')}
          />
        )}
        {errorMessage && (
          <InfoAlert
            message={errorMessage}
            close={() => setErrorMessage('')}
            type="error"
          />
        )}
      </div>
      <div className="flex min-h-screen items-center  flex-1 flex-col justify-center px-2 py-4 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-12 sm:h-20 w-auto"
            src="/courseTrackerIcon.png"
            alt="Course Tracker"
          />
          <h2 className="mt-2 text-center  text-xl sm:text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" action="#" method="POST">
            <div>
              <label
                htmlFor="email"
                className="block text-xs sm:text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  onChange={handleEmailChange}
                  required
                  className="block w-full rounded-md border-0 py-1 sm:py-1.5 leading-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className=" flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="text-xs sm:text-sm  block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                <div className="text-sm">
                  <a
                    href="#"
                    onClick={handleDialogOpen}
                    className="text-xs sm:text-sm font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot password?
                  </a>
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  onChange={handlePasswordChange}
                  required
                  className="block w-full rounded-md border-0 py-1 leading-4 sm:py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                onClick={handleSignin}
                className="flex w-full items-center justify-center rounded-md bg-indigo-600 px-3 py-1 sm:py-1.5 text-xs sm:text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sign in
                {isLoading && <ButtonLoader />}
              </button>
            </div>
          </form>

          <p className=" text-xs sm:text-sm mt-5 sm:mt-10 text-center text-sm text-gray-500">
            Don't have an account?{' '}
            <Link
              to="/signup"
              className=" text-xs sm:text-sm font-semibold leading-4 sm:leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Sign up
            </Link>
          </p>
        </div>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Forgot Password?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To reset password link, please enter your email address below
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            onChange={handleResetEmailChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSendEmail}>Send email</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Signin;
