import { useState } from 'react';
import courseDetailsPic from './images/course-det.png';
import statsPic from './images/stats-page.png';
import UpcomingTasksPic from './images/upcoming-tasks.png';
import UpcomingTasksPicMb from './images/upcoming-mb.png';

const features = [
  {
    name: 'Goal grades, simulations and predictions',
    description:
      'Goal grades for each component helps you divide and conquer and structure your plan in a way that sets you up for success.',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="darksalmon"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-5 inline"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
        />
      </svg>
    ),
    image: {
      base: courseDetailsPic,
      mb: courseDetailsPic,
    },
  },

  {
    name: 'Timer and Stats',
    description:
      'Use built-in timer/stopwatch to study and get vital insight into your study habits. Daily streaks for added motivation!',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="lightsalmon"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-5 inline"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z"
        />
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
      </svg>
    ),
    image: {
      base: statsPic,
      mb: statsPic,
    },
  },
  {
    name: 'Stay on top',
    description:
      'See upcoming deadlines along side its priority categorized by weeks in order for you to stay on top!',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="goldenrod"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-5 inline"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0"
        />
      </svg>
    ),
    image: {
      base: UpcomingTasksPic,
      mb: UpcomingTasksPicMb,
    },
  },
];
const nonSelectedStyle =
  'relative pl-9 py-2 transition  duration-300 ease-in-out hover:bg-gray-200 hover:border-gray-400 hover:rounded hover:cursor-pointer';
const selectedStyle =
  'relative pl-9 py-2 transition  duration-300 ease-in-out bg-gray-200 border-gray-400 rounded hover:cursor-pointer';
const FeatureSection = () => {
  const [showImage, setShowImage] = useState(features[0].image);
  return (
    <div className="overflow-hidden bg-white pt-20 pb-3 sm:pt-32 sm:pb-6">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">
                Do Better
              </h2>

              <p className="mt-2 text-2xl sm:text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Boost your productivity
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="goldenrod"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="ml-2 inline h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z"
                  />
                </svg>
              </p>
              <p className="mt-6 text-base sm:text-lg leading-8 text-gray-600">
                Lay out structure for how to achieve goal grade, use in-app
                timers to study, track stats to better understand study habits,
                see upcoming tasks and get reminders!
              </p>
              <dl className="mt-5 max-w-xl space-y-2 text-sm sm:text-base leading-7 text-gray-600 lg:max-w-none ">
                {features.map((feature) => (
                  <div
                    onClick={() => {
                      setShowImage(feature.image);
                    }}
                    key={feature.name}
                    className={
                      showImage === feature.image
                        ? selectedStyle
                        : nonSelectedStyle
                    }
                  >
                    <dt className="font-semibold text-gray-900">
                      {feature.icon} {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>

          {/* for non-mobile screen */}
          <div class="hidden sm:block sm:positon-relative sm:top-0 sm:bottom-0 sm:mt-auto sm:mb-auto ">
            <img
              src={showImage.base}
              alt="Product screenshot"
              className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[42rem] lg:w-[32rem] xl:w-[40rem] 2xl:w-[50rem] md:-ml-4 lg:-ml-0"
              width={1200}
              height={1400}
            />
          </div>

          {/* for mobile */}
          <div class="flex justify-center sm:hidden">
            <img
              src={showImage.mb}
              alt="Product screenshot"
              className="w-[22rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[42rem] lg:w-[32rem] xl:w-[40rem] 2xl:w-[50rem] md:-ml-4 lg:-ml-0"
              width={1200}
              height={1400}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureSection;
