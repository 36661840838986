import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCumulativeGpa } from '../Contexts/cumulativeGpaContext';
import { mapLetterGradeToVal } from './util';
import { axiosInstance } from '../axiosApi';
import {
  setCustomGradeSettings,
  setCustomGradeSettingsObj,
} from '../redux/actions/customGradeSettingsActions';
import { convertToList } from './util';

const CumulativeGpa = () => {
  const [totalGpa, setTotalGpa] = useState('');

  const allSemCourses = useSelector((state) => state.courseReducer);
  const getUserId = () => localStorage.getItem('user_id');
  const customGradeSettingsObj = useSelector((state) => {
    return state.customGradeSettingsReducer.customGradeSettingsObj;
  });
  const outOfGpa = customGradeSettingsObj
    ? Math.max(...Object.values(customGradeSettingsObj))
    : 0;

  const dispatch = useDispatch();
  const fetchGradePointSettings = async () => {
    try {
      const response = await axiosInstance.get(
        `user/gradePointSettings/${getUserId()}`
      );
      const data = response.data;
      dispatch(
        setCustomGradeSettings(
          convertToList(data.customGradeSettings.custom_grade_scale)
        )
      );
      dispatch(
        setCustomGradeSettingsObj(data.customGradeSettings.custom_grade_scale)
      );
      return response;
    } catch (error) {
      alert(`Error! ${error.message}`);
      throw error;
    }
  };

  useEffect(() => {
    if (
      !customGradeSettingsObj ||
      Object.keys(customGradeSettingsObj).length === 0
    ) {
      fetchGradePointSettings();
    }
  }, []);

  useEffect(() => {
    if (
      customGradeSettingsObj &&
      Object.keys(customGradeSettingsObj).length !== 0
    )
      calcTotalGpa();
  }, [customGradeSettingsObj, allSemCourses]);

  const calcTotalGpa = () => {
    let numCredits = 0;
    let totalPoints = 0;
    const semCourses = Object.values(allSemCourses);
    const allCourses = [];
    for (let courses of semCourses) {
      allCourses.push(...courses);
    }
    for (let course of allCourses) {
      if (
        course.letterGrade === 'Grade' ||
        course.letterGrade === 'In Progress' ||
        course.letterGrade === 'Upcoming'
      )
        continue;

      if (course.credit === 0) {
        continue;
      }

      numCredits += course.credit;

      if (
        !customGradeSettingsObj ||
        !customGradeSettingsObj.hasOwnProperty(course.letterGrade)
      ) {
        continue;
      }
      totalPoints += customGradeSettingsObj[course.letterGrade] * course.credit;
    }
    setTotalGpa((totalPoints / numCredits).toFixed(1)); // TODO: assuming 3 course cred for all now, will have to fix later and customize.
  };

  return (
    <div className="flex justify-end border border-transparent border-solid border-opacity-0 mb-2 sm:mb-5 mt-5 sm:mt-10 mr-2 font-semibold text-lg sm:text-xl md:text-xl">
      <span>
        Cumulative GPA:{' '}
        {isNaN(totalGpa)
          ? 'Please enter grades'
          : `${totalGpa}/${outOfGpa.toFixed(1)}`}{' '}
      </span>
    </div>
  );
};

export default CumulativeGpa;
