import { configureStore } from '@reduxjs/toolkit';
import semesterReducer from '../reducers/semesterReducer';
import courseReducer from '../reducers/courseReducer';
import customGradeSettingsReducer from '../reducers/customGradeSettingsReducer';

import { combineReducers } from 'redux';
const reducer = combineReducers({
  semesterReducer,
  courseReducer,
  customGradeSettingsReducer,
});
const store = configureStore({
  reducer,
});

export default store;
