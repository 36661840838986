import React, { useState, useEffect } from 'react';
import ApexCharts from 'apexcharts';
import { PieChart } from './PieChart';
import TrajectoryGraph from './TrajectoryGraph';
import { axiosInstance } from '../axiosApi';

const MONTH_NAMES = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export const GraphicalStats = () => {
  const [dates, setDates] = useState([]);
  const [formattedDates, setFormattedDates] = useState([]);
  const [stats, setStats] = useState([]);
  const [statsPerDay, setStatsPerDay] = useState([]);
  const [courses, setCourses] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const getUserId = () => localStorage.getItem('user_id');
  // Get past week stats
  const fetchPastWeekStats = async () => {
    try {
      const todayDate = new Date().getDate();

      const end = new Date();
      end.setDate(todayDate);

      const start = new Date();
      start.setDate(todayDate - 6);

      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await axiosInstance.get(`stats/`, {
        params: {
          user_id: getUserId(),
          start_date: start.toISOString(),
          end_date: end.toISOString(),
          timezone: userTimeZone,
        },
      });

      const dailyStats = response.data.courseStats;
      setStats(Object.values(dailyStats));
      setDates(Object.keys(dailyStats));

      return response;
    } catch (error) {
      alert(`Error! ${error.message}`);
      //console.log(error);
      throw error;
    }
  };
  useEffect(() => {
    fetchPastWeekStats();
  }, []);

  useEffect(() => {
    if (dates.length <= 0) return;

    setFormattedDates(dates.map((date) => transformDate(date)));
  }, [dates]);

  function transformDate(date) {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];

    let [curYear, month, day] = date.split('-');
    const dateObject = new Date(curYear, parseInt(month) - 1, day);
    const curMonth = MONTH_NAMES[dateObject.getMonth()];
    const curDate = dateObject.getUTCDate();
    const curDayPos = dateObject.getUTCDay();
    let curDay = '';
    if (curDayPos >= 0) {
      curDay = days[curDayPos];
    }

    return `${curDate} ${curMonth} (${curDay})`;
  }

  return (
    <div className="">
      <h1 className="mb-5 text-center sm:mb-3 text-lg font-semibold  text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
        Graphical Stats
      </h1>
      {stats.length > 0 && formattedDates.length > 0 && (
        <div className="flex  flex-col sm:flex-row justify-between sm:ml-10 lg:ml-15">
          <PieChart formattedDates={formattedDates} dailyStats={stats} />
          <TrajectoryGraph formattedDates={formattedDates} dailyStats={stats} />
        </div>
      )}
      {!stats.length && (
        <div>
          <h1> No stats in the past week to show</h1>
        </div>
      )}
    </div>
  );
};

export default GraphicalStats;
