import NewNavBar from './NewNavBar';
import { useHistory } from 'react-router-dom';
import { axiosInstance } from '../axiosApi';
import React, { useState, useEffect } from 'react';
import DailyStreaks from './DailyStreaks';
import StatAverage from './StatAverage';

const OtherStats = () => {
  const history = useHistory();
  const getUserId = () => localStorage.getItem('user_id');

  return (
    <div className="flex flex-row justify-center mb-10">
      <div className="flex flex-col justify-center items-center  sm:tracking-tight sm:mr-20 ">
        <h1 className="mt-8 xl:mt-0 text-center  mb-7 text-lg font-semibold text-gray-900  sm:truncate sm:text-2xl ">
          Other Stats
        </h1>
        <DailyStreaks />
        <StatAverage />
      </div>
    </div>
  );
};

export default OtherStats;
