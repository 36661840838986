import React from 'react';
import Main from './Components/Main';
import { Route, Switch } from 'react-router-dom';
import ResetPassword from './Components/ResetPassword';
import CourseDetails from './Components/CourseDetails/CourseDetails';
import ProtectedRoute from './Components/ProtectedRoute';
import NotFound from './Components/NotFound';
import Signin from './Components/Signin';
import Signup from './Components/Signup';
import { AuthAlertProvider } from './Contexts/authAlertContext';
import Settings from './Components/Settings';
import UpcomingTasks from './Components/UpcomingTasks';
import Stats from './Components/Stats';
import Landing from './Components/LandingPage/Landing';
import TimerContextProvider from './Components/Contexts/TimerContextProvider';
import TimerPage from './Components/TimerPage';
class App extends React.Component {
  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-JLRKMBCHBY';
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-JLRKMBCHBY');
    };
  }

  render() {
    return (
      <AuthAlertProvider>
        <Switch>
          <Route path="/" exact>
            <Landing />
          </Route>
          <Route path="/landing" exact>
            <Landing />
          </Route>
          <Route path="/signin" exact>
            <Signin />
          </Route>
          <Route path="/verify/email/:token" exact>
            <Signin isEmailVerification={true} />
          </Route>
          <Route path="/signup" exact>
            <Signup />
          </Route>
          <Route path="/reset/password/:token" exact>
            <ResetPassword />
          </Route>

          {/* Restricted pages */}
          <ProtectedRoute
            exact
            path="/home"
            component={Main}
            // <Route path="/home" exact>
            //   <Main/>
            // </Route>
          />
          <ProtectedRoute exact path="/settings" component={Settings} />
          <ProtectedRoute exact path="/stats" component={Stats} />
          <ProtectedRoute exact path="/upcoming" component={UpcomingTasks} />
          <ProtectedRoute exact path="/settings" component={Settings} />

          <TimerContextProvider>
            <Switch>
              <ProtectedRoute exact path="/timer" component={TimerPage} />
              <ProtectedRoute
                exact
                path="/courseDetails/:id"
                component={CourseDetails}
              />
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </TimerContextProvider>
        </Switch>
      </AuthAlertProvider>
    );
  }
}

export default App;
