import React, { useState, useEffect } from 'react';
import { axiosInstance } from '../axiosApi';
import { avatarClasses } from '@mui/material';

const StatAverage = () => {
  const [averageStudyTime, setAverageStudyTime] = useState(0);
  const [metricType, setMetricType] = useState('');
  const getUserId = () => localStorage.getItem('user_id');
  const fetchAverageStats = async () => {
    try {
      const endDate = new Date();
      endDate.setDate(endDate.getDate());
      const startDate = new Date(); // Current date and time
      startDate.setDate(endDate.getDate() - 5);
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await axiosInstance.get(`stats/average/`, {
        params: {
          user_id: getUserId(),
          start_date: startDate.toISOString(),
          end_date: endDate.toISOString(),
          timezone: userTimeZone,
        },
      });

      setAverageStudyTime(response.data.average_study_time);
      setMetricType(response.data.metric_type.toLowerCase());

      return response;
    } catch (error) {
      alert(`Error! ${error.message}`);
      //console.log(error);
      throw error;
    }
  };
  useEffect(() => {
    fetchAverageStats();
  }, []);
  return (
    <div className="flex flex-col sm:flex-row justify-center m-auto  text-center mt-6 ">
      {/* <div
        href="#"
        class="mt-2 block w-full p-6  border border-gray-200 rounded-lg shadow hover:bg-gray-100 "
      >
        <h5 class="mb-2 text-base sm:text-lg  font-bold  text-gray-900 ">
          Average study session
        </h5>

        <div class="flex justify-center text-sm font-small text-gray-700 ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="bisque"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="mt-1 w-4 h-4"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>

          <span className="font-bold sm:ml-1">Coming Soon</span>
        </div>
        <p className="mt-1 sm:mt-3 truncate text-xs text-gray-500">
          Past 7 days
        </p>
      </div> */}
      <a
        href="#"
        class="flex flex-col justify-between sm:ml-2 mt-2 block w-full p-6  border border-gray-200 rounded-lg shadow hover:bg-gray-100 "
      >
        <h5 class="mb-2 text-base sm:text-lg  font-bold text-gray-900 ">
          Average daily study time
        </h5>
        <div class=" flex justify-center font-normal text-gray-700 ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="bisque"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="mt-2 w-4 h-7"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>

          <span className="text-start ml-1 font-semibold mt-2 text-sm sm:text-base">
            {averageStudyTime} {metricType}
          </span>
        </div>
        <p className="mt-1 sm:mt-4 truncate text-xs text-gray-500">
          Past 7 days
        </p>
      </a>
    </div>
  );
};

export default StatAverage;
