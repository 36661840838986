import React, { useState, useEffect } from 'react';
import Semester from './Semester';
import { useHistory } from 'react-router-dom';
import { axiosInstance } from '../axiosApi';
import Alert from '@mui/material/Alert';
import NewNavBar from './NewNavBar';
import { useDispatch, useSelector } from 'react-redux';
import { removeSemester, setSemesters } from '../redux/actions/semesterActions';
import { resetCourses, setCourses } from '../redux/actions/courseActions';
import {
  setCustomGradeSettings,
  setCustomGradeSettingsObj,
} from '../redux/actions/customGradeSettingsActions';
import CumulativeGpa from './CumulativeGpa';
import { convertToList } from './util';
import { sortSems, findIndex } from './util';
import FeatureModal from './FeatureModal';
import LoadingSpinner from './LoadingSpinner';

const Main = () => {
  const [newSemester, setNewSemester] = useState('');
  const [errorAlert, setErrorAlert] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [showFeatureModal, setShowFeatureModal] = useState(false);

  const [isNew, setIsNew] = useState(true);
  const [allCourses, setAllCourses] = useState([]);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const semesters = useSelector((state) => {
    return state.semesterReducer.semesters;
  });
  const customGradeSettings = useSelector((state) => {
    return state.customGradeSettingsReducer.customGradeSettings;
  });
  const dispatch = useDispatch();

  const fetchGradePointSettings = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        `user/gradePointSettings/${getUserId()}`
      );

      const data = response.data;
      dispatch(
        setCustomGradeSettings(
          convertToList(data.customGradeSettings.custom_grade_scale)
        )
      );
      dispatch(
        setCustomGradeSettingsObj(data.customGradeSettings.custom_grade_scale)
      );
      setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
      alert(`Error! ${error.message}`);
      //console.log(error);
      throw error;
    }
  };

  const fetchSemesters = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`semesters/${getUserId()}`, {
        user_id: getUserId(),
      });

      let sortedSems = sortSems(response.data.semesters);
      dispatch(setSemesters(sortedSems));
      setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
      alert(`Error! ${error.message}`);
      //console.log(error);
      throw error;
    }
  };

  // const fetchShowFeature = async () => {
  //   try {
  //     const response = await axiosInstance.get(`user/feature`, {
  //       params: {
  //         user_id: getUserId(),
  //       },
  //     });
  //     setShowFeatureModal(response.data.showFeature);

  //     return response;
  //   } catch (error) {
  //     alert(`Error! ${error.message}`);
  //     //console.log(error);
  //     throw error;
  //   }
  // };

  const closeFeatureModal = () => {
    setShowFeatureModal(false);
  };

  useEffect(() => {
    document.title = 'Home | Course Tracker';
    if (semesters.length === 0) {
      fetchSemesters();
    }
    //fetchShowFeature();

    if (customGradeSettings && customGradeSettings.length === 0) {
      fetchGradePointSettings();
    }
  }, []);

  const getUserId = () => localStorage.getItem('user_id');

  const onChangeHandler = (event) => {
    setNewSemester(event.target.value);
  };

  const alreadyExists = (newSem) => {
    for (let semester of semesters) {
      if (semester.name.toLowerCase().trim() === newSem.toLowerCase().trim())
        return true;
    }
    return false;
  };

  const isValidSemName = (semName) => {
    let name = semName.toLowerCase();
    if (
      !name.startsWith('fall') &&
      !name.startsWith('winter') &&
      !name.startsWith('spring') &&
      !name.startsWith('summer') &&
      !name.startsWith('autumn')
    )
      return false;

    let digitIdx = findIndex(semName);
    if (digitIdx === -1) return false;

    let strYear = semName.substring(digitIdx);

    try {
      const numYear = Number(strYear);
    } catch {
      alert('Not a number');
      return false;
    }
    return true;
  };

  const addSemesterHandler = async (event) => {
    try {
      //console.log(`user id = ${getUserId()}`);
      if (alreadyExists(newSemester)) {
        setErrorAlert(`${newSemester} already exists!`);
        setShowAlert(false);
        return;
      }

      if (!newSemester.trim()) {
        setErrorAlert(`Semester name cannot be blank`);
        setShowAlert(false);
        return;
      }

      // if (!isValidSemName(newSemester)) {
      //   let errorMessage = `Semester name must start with a Season followed by a year. Eg- 'Fall 2018'`;
      //   setErrorAlert(errorMessage);
      //   setShowAlert(false);
      //   setTimeout(() => {
      //     setErrorAlert('');
      //   }, 3000);
      //   return;
      // }
      const data = {
        user_id: getUserId(),
        name: newSemester,
        isNew: isNew.toString(),
      };
      const response = await axiosInstance.post('semesters/', data);

      dispatch(setSemesters(sortSems([response.data, ...semesters])));

      setNewSemester('');
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      setErrorAlert('');
      return response;
    } catch (error) {
      alert(`Error! ${error.message}`);
      //console.log(error);
      throw error;
    }
  };

  const deleteSemesterHandler = async (semester) => {
    try {
      const data = {
        id: semester.uuid,
        user_id: getUserId(),
      };
      const response = await axiosInstance.delete('semesters/', { data });
      dispatch(removeSemester(semester.uuid));
      dispatch(resetCourses(semester.uuid));
      return response;
    } catch (error) {
      alert(`Error! ${error.message}`);
      //console.log(error);
      throw error;
    }
  };

  const closeErrorHandler = () => {
    setErrorAlert('');
  };

  const closeSuccessHandler = () => {
    setShowAlert(false);
  };

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  return (
    <div>
      <NewNavBar />
      <div className="w-full absolute top-5 left-2 ">
        {showFeatureModal && (
          <FeatureModal closeFeatureModal={closeFeatureModal} />
        )}

        {errorAlert && (
          <Alert
            style={{ width: '40%', margin: '0 auto' }}
            severity="error"
            onClose={closeErrorHandler}
          >
            {errorAlert}
          </Alert>
        )}
        {showAlert && (
          <div
            id="toast-success"
            class="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow "
            role="alert"
          >
            <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-5 text-green-300 rounded-lg ">
              <svg
                class="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
              </svg>
              <span class="sr-only">Check icon</span>
            </div>
            <div class="ml-3 text-sm font-normal">
              Semester added successfully
            </div>
          </div>
        )}
        {showSuccessToast && (
          <div
            id="toast-success"
            class="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow "
            role="alert"
          >
            <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-5 text-green-300 rounded-lg ">
              <svg
                class="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
              </svg>
              <span class="sr-only">Check icon</span>
            </div>
            <div class="ml-3 text-sm font-normal">
              Changes saved successfully
            </div>
          </div>
        )}
      </div>
      <div class="flex flex-col items-center mt-2">
        <h1 class=" mb-3.5 sm:mb-7 text-sm sm:text-lg md:text-xl font-bold">
          Welcome To Course Tracker
        </h1>
        <div class="w-fit rounded-md shadow-sm">
          <div className="mb-2"></div>
          <input
            type="text"
            name="semester"
            id="semester"
            class="block rounded-md border-0 py-1.5 sm:py-3 pl-2 sm:pl-3 pr-10 sm:pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-xs leading-5 sm:text-sm md:text-lg sm:leading-6"
            placeholder="Fall 2023"
            value={newSemester}
            onChange={onChangeHandler}
          ></input>
          <button
            type="submit"
            onClick={addSemesterHandler}
            className="mt-2 mb-5 sm:mb-10 flex w-full items-center justify-center rounded-md bg-indigo-600 px-3 py-1 sm:py-1.5 text-xs sm:text-sm font-semibold leading-4 sm:leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 md:leading-6 md:text-lg md:h-10 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Add Semester
          </button>
        </div>
      </div>
      <ul>
        {semesters.map((semester, idx) => (
          <li key={semester.uuid}>
            <Semester
              deleteSemesterHandler={deleteSemesterHandler}
              semester={semester}
              setShowSuccessToast={setShowSuccessToast}
            />
          </li>
        ))}
      </ul>
      {isLoading && (
        <div className="flex justify-center mt-3">
          {' '}
          <LoadingSpinner />{' '}
        </div>
      )}
      {!isLoading && semesters.length === 0 && (
        <p class="flex justify-center align-center">Please add a semester ☹</p>
      )}

      {semesters.length > 0 && <CumulativeGpa />}
    </div>
  );
};

export default Main;
