const Toast = ({ text }) => {
  return (
    <div
      id="toast-success"
      class="absolute left-2 flex items-center  max-w-xs pl-2 pr-6 py-4 mb-4 ml-4 text-gray-500 bg-white rounded-lg shadow "
      role="alert"
    >
      <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-5 text-green-300 rounded-lg ">
        <svg
          class="w-5 h-5"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
        </svg>
        <span class="sr-only">Check icon</span>
      </div>
      <div class="ml-1 text-sm font-normal">{text}</div>
    </div>
  );
};

export default Toast;
