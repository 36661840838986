const COLORS = {
  green: 'ring-green-600/20 text-green-700 bg-green-50',
  red: 'bg-red-50  text-red-700 ring-red-600/10',
};

const Badge = ({ text, color = 'green' }) => {
  return (
    <span
      title={text}
      className={`truncate block items-center rounded-md  px-2 py-1 text-xs font-medium  ring-1 ring-inset ${COLORS[color]}`}
    >
      {text}
    </span>
  );
};

export default Badge;
