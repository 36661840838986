import React from 'react';
import { TimerContext } from './Contexts/TimerContextProvider';
import {
  startIcon,
  resetIcon,
  stopIcon,
  resumeIcon,
  pauseIcon,
} from '../utils/timerUtils';
const TimerActions = ({ addTime, setupTimer }) => {
  const {
    timerOn,
    isPersisting,
    timerPaused,
    pauseTimer,
    resetTimer,
    resumeTimer,
  } = React.useContext(TimerContext);

  return (
    <div id="timer-action-buttons" className="cursor-pointer  ml-3">
      {!timerOn && !timerPaused && (
        <button
          title="Start timer"
          onClick={setupTimer}
          disabled={isPersisting}
        >
          {startIcon}
        </button>
      )}
      {timerPaused && (
        <button
          title="Resume timer"
          onClick={resumeTimer}
          disabled={isPersisting}
        >
          {resumeIcon}
        </button>
      )}

      {timerOn && !timerPaused && (
        <button
          title="Pause timer"
          onClick={pauseTimer}
          disabled={isPersisting}
        >
          {pauseIcon}
        </button>
      )}
      {(timerOn || timerPaused) && (
        <span>
          <button
            title="Stop and add time"
            onClick={addTime}
            disabled={isPersisting}
          >
            {stopIcon}
          </button>
          <button
            title="Reset timer"
            onClick={resetTimer}
            disabled={isPersisting}
          >
            {resetIcon}
          </button>
        </span>
      )}
    </div>
  );
};

export default TimerActions;
