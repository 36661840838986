import Alert from '@mui/material/Alert';

const InfoAlert = (props) => {
  return (
    <Alert
      severity={props.type}
      style={{ width: '40%', margin: '0 auto' }}
      onClose={props.close}
    >
      {props.message}
    </Alert>
  );
};

export default InfoAlert;
