import { Fragment, useEffect, useRef, useState } from 'react'; // Import useState
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline'; // Updated icon import
import { useSelector } from 'react-redux';

const grades = [
  'In Progress',
  'A+',
  'A',
  'A-',
  'B+',
  'B',
  'B-',
  'C+',
  'C',
  'C-',
  'D+',
  'D',
  'F',
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function GradeDropdown(props) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const customGradeSettingsObj = useSelector((state) => {
    return state.customGradeSettingsReducer.customGradeSettingsObj;
  });

  const grades = customGradeSettingsObj
    ? Object.keys(customGradeSettingsObj).sort((a, b) => {
        // Extract the main type of the grade (e.g., A, B)
        const typeA = a.replace(/[+-]/g, '');
        const typeB = b.replace(/[+-]/g, '');

        // Compare the main types first
        if (typeA < typeB) return -1;
        if (typeA > typeB) return 1;

        // If main types are equal, compare grades with the "+" sign first
        if (a.includes('+') && !b.includes('+')) return -1;
        if (!a.includes('+') && b.includes('+')) return 1;

        // If both have a "+" sign or none, maintain their relative order
        return 0;
      })
    : [];

  grades.push('In Progress');

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    window.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          onClick={toggleDropdown}
          className="inline-flex justify-between  w-28 md:w-32 rounded-md bg-white px-1.5 py-1 md:text-base text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          style={{}}
        >
          {props.selectedGrade}
          <ChevronDownIcon
            className="-mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <div ref={dropdownRef}>
        <Transition
          as={Fragment}
          show={isDropdownOpen}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-[10rem] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {grades.map((grade) => (
                <Menu.Item key={grade}>
                  {({ active }) => (
                    <a
                      href="#"
                      onClick={(e) => {
                        props.handleGradeDropDownChange(
                          e,
                          grade,
                          props.course,
                          props.idx
                        );
                        setIsDropdownOpen(false);
                      }}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm'
                      )}
                    >
                      {grade}
                    </a>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </div>
    </Menu>
  );
}
