import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const BaseForm = ({
  isOpen = true,
  title,
  description = '',
  confirmButtonText,
  handleSubmit,
  handleCancel,
  children,
}) => {
  return (
    <Dialog open={isOpen} onClose={handleCancel}>
      <DialogTitle>
        {title}
        {description && (
          <p className="mt-1 text-sm">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="mr-1 w-6 h-6 inline"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
              />
            </svg>
            {description}
          </p>
        )}
      </DialogTitle>
      <DialogContent> {children} </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleSubmit}>{confirmButtonText}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default BaseForm;
