import { axiosInstance } from '../axiosApi';
import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import './ResetPassword.css';
import Alert from '@mui/material/Alert';
import Navbar from './Navbar';
import ButtonLoader from './ButtonLoader';
import InfoAlert from './infoAlert';

function ResetPassword() {
  const params = useParams();
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorAlert, setErrorAlert] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const resetPasswordHandeler = (event) => {
    event.preventDefault();

    setIsLoading(true);
    if (confirmPassword !== password) {
      setErrorAlert('Passwords do not match, please try again');
      return;
    }
    const data = {
      new_password: password,
      token: params.token,
    };
    let message = '';
    axiosInstance
      .patch('reset/password/', data)
      .then(() => {
        //console.log('Response Data', response.data);
        history.push('/signin', { resetPassword: true });
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          //console.log('Error Response Data', error.response.data);
          const res = error.response.data;
          Object.keys(res).map((keyName) => {
            for (let i = 0; i < res[keyName].length; i += 1) {
              message += `${keyName}: ${res[keyName][i]}`;
            }

            return res[keyName];
          });
          console.log(message);
          setErrorAlert(message);
          //console.log('Error Response Status', error.response.status);
          //console.log('Error Response Headers', error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          //console.log('Error Request', error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          //console.log('Error', error.message);
        }
        setIsLoading(false);
      });
  };

  const passwordHandeler = (event) => {
    setPassword(event.target.value);
  };

  const confirmPasswordHandeler = (event) => {
    setConfirmPassword(event.target.value);
  };

  const closeErrorHandler = () => {
    setErrorAlert('');
  };

  const goToSignin = () => {
    history.push('/signin');
  };

  return (
    <>
      <div className="w-full absolute top-5">
        {errorAlert && (
          <InfoAlert
            message={errorAlert}
            close={() => {
              setErrorAlert('');
              return closeErrorHandler();
            }}
            type="error"
          />
        )}
      </div>
      <div className="flex min-h-screen items-center  flex-1 flex-col justify-center px-2 py-4 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-20 w-auto"
            src="/courseTrackerIcon.png"
            alt="Course Tracker"
          />
          <h2 className="mt-2 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Reset your password
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" action="#" method="POST">
            <div>
              <label
                htmlFor="newPassword"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                New password
              </label>
              <div className="mt-2">
                <input
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  autoComplete="newPassword"
                  onChange={(e) => passwordHandeler(e)}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div>
                <label
                  htmlFor="newPassword"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  New password
                </label>
                <div className="mt-2">
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    autoComplete="confirmPassword"
                    onChange={(e) => confirmPasswordHandeler(e)}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div>
              <button
                type="submit"
                onClick={(e) => resetPasswordHandeler(e)}
                className="flex w-full items-center justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Reset Password
                {isLoading && <ButtonLoader />}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
