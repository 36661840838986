import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { green } from '@mui/material/colors';
import Icon from '@mui/material/Icon';
import { loadCSS } from 'fg-loadcss';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useHistory } from 'react-router-dom';
import DialogTitle from '@mui/material/DialogTitle';
import './Semester.css';
import { axiosInstance } from '../axiosApi';
import AddBoxIcon from '@mui/icons-material/AddBox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { maxWidth } from '@mui/system';
import GradeDropdown from './GradeDropdown';
import { mapLetterGradeToVal } from './util';
import CourseDialog from './CourseDialog';
import { useDispatch, useSelector } from 'react-redux';
import {
  addCourse,
  editCourse,
  removeCourse,
  setCourseLetterGrade,
  setCourses,
} from '../redux/actions/courseActions';
import {
  setCustomGradeSettings,
  setCustomGradeSettingsObj,
} from '../redux/actions/customGradeSettingsActions';
import { convertToList } from './util';
import { addCourseToSemester } from '../redux/actions/semesterActions';

import {
  removeCourseFromSemester,
  updateLetterGradeOfCourseInSem,
} from '../redux/actions/semesterActions';
import Modal from './Modal';

const Semester = (props) => {
  const [newCourse, setNewCourse] = useState('');
  const [open, setOpen] = useState(false);
  const [courseToDel, setCourseToDel] = useState('');
  const [semGPA, setSemGPA] = useState(-1);
  const history = useHistory();
  const [courseDelOpen, setCourseDelOpen] = useState({});
  const [semesterDelModalOpen, setSemesterDelModalOpen] = useState(false);
  const [openCourseDialog, setOpenCourseDialog] = useState(false);
  const [openEditCourseDialog, setOpenEditCourseDialog] = useState(false);
  const [editingCourse, setEditingCourse] = useState({});

  const courses = useSelector((state) => {
    const semesterCourses = state.courseReducer[props.semester.uuid];
    return semesterCourses || [];
  });

  const customGradeSettingsObj = useSelector((state) => {
    return state.customGradeSettingsReducer.customGradeSettingsObj;
  });

  const outOfGpa = customGradeSettingsObj
    ? Math.max(...Object.values(customGradeSettingsObj))
    : 0;
  const dispatch = useDispatch();
  const fetchGradePointSettings = async () => {
    try {
      const response = await axiosInstance.get(
        `user/gradePointSettings/${getUserId()}`
      );
      const data = response.data;

      dispatch(
        setCustomGradeSettings(
          convertToList(data.customGradeSettings.custom_grade_scale)
        )
      );
      dispatch(
        setCustomGradeSettingsObj(data.customGradeSettings.custom_grade_scale)
      );
      return response;
    } catch (error) {
      alert(`Error! ${error.message}`);
      throw error;
    }
  };

  useEffect(() => {
    if (
      !customGradeSettingsObj ||
      Object.keys(customGradeSettingsObj).length === 0
    ) {
      fetchGradePointSettings();
    }
  }, []);

  useEffect(() => {
    if (!courses || courses.length === 0) {
      dispatch(setCourses(props.semester));
    }

    if (
      customGradeSettingsObj &&
      Object.keys(customGradeSettingsObj).length !== 0
    )
      calcSemGPA();
  }, [courses, customGradeSettingsObj]);

  const handleClickOpenDelCourseDialog = (courseId) => {
    setCourseDelOpen((prevState) => ({
      ...prevState,
      [courseId]: true,
    }));
  };

  const handleCloseDelCourseDialog = (courseId) => {
    setCourseDelOpen((prevState) => ({
      ...prevState,
      [courseId]: false,
    }));
  };

  const calcSemGPA = () => {
    let numCreds = 0;
    let gpa = 0;
    let letterGrade;
    for (let course of courses) {
      letterGrade = course.letterGrade;
      if (
        letterGrade === 'Grade' ||
        letterGrade === 'In Progress' ||
        letterGrade === 'Upcoming'
      ) {
        setSemGPA(-1);
        return;
      }

      if (course.credit === 0) {
        continue;
      }

      numCreds += course.credit;
      if (
        !customGradeSettingsObj ||
        !customGradeSettingsObj.hasOwnProperty(letterGrade)
      ) {
        // console.log('Sem rerror');
        continue;
      }
      gpa += customGradeSettingsObj[letterGrade] * course.credit;
    }

    if (numCreds === 0) {
      setSemGPA(-1);
      return;
    }
    gpa = gpa / numCreds;
    setSemGPA(gpa.toFixed(1));
  };

  const onChangeHandler = (event) => {
    setNewCourse(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  // const handleClickOpenDelCourseDialog = (course) => {
  //   setCourseDelOpen(true);
  //   setCourseToDel(course);
  // };

  const getUserId = () => localStorage.getItem('user_id');

  const handleDisagreeToDeleteCourse = () => {
    setCourseDelOpen(false);
  };

  const handleAgreeToDeleteSem = async (deleteSemesterHandler, semester) => {
    await deleteSemesterHandler(semester);
    setOpen(false);
  };

  const handleDisagreeToDeleteSem = () => {
    setOpen(false);
  };

  const addCourseHandler = async (courseName, numCredits) => {
    try {
      let data = {};
      if (props.semester.isNew) {
        data = {
          name: courseName,
          credit: numCredits,
          letterGrade: 'In Progress',
        };
      } else {
        data = {
          name: courseName,
          credit: numCredits,
          letterGrade: 'Grade',
        };
      }

      const response = await axiosInstance.post(
        `courses/${props.semester.uuid}`,
        data
      );

      dispatch(addCourse(response.data, props.semester.uuid));
      dispatch(addCourseToSemester(response.data, props.semester.uuid));
      setSemGPA(-1);
      setNewCourse('');
      setOpenCourseDialog(false);
      props.setShowSuccessToast(true);
      setTimeout(() => {
        props.setShowSuccessToast(false);
      }, 3000);
      return response;
    } catch (error) {
      alert(`Error! ${error.message}`);
      throw error;
    }
  };
  const deleteCourse = async (toBeDeletedCourse) => {
    try {
      const data = {
        id: toBeDeletedCourse.uuid,
        user_id: getUserId(),
      };
      const response = await axiosInstance.delete('courses/', { data });
      dispatch(removeCourse(toBeDeletedCourse, props.semester.uuid));
      dispatch(
        removeCourseFromSemester(toBeDeletedCourse, props.semester.uuid)
      );
      setCourseDelOpen(false);
      return response;
    } catch (error) {
      alert(`Error! ${error.message}`);
      setCourseDelOpen(false);
      throw error;
    }
  };

  const viewDetailsHandler = (courseId, courseName) => {
    history.push(`courseDetails/${courseId}`, { courseName: courseName });
  };

  const handleGradeDropDownChange = async (e, grade, course, idx) => {
    e.persist();
    e.preventDefault();
    try {
      const data = {
        ...course,
        letterGrade: grade,
      };
      await axiosInstance.put(`courses/${course.uuid}`, {
        ...data,
      });
      dispatch(setCourseLetterGrade(props.semester.uuid, course, grade));
      dispatch(
        updateLetterGradeOfCourseInSem(props.semester.uuid, course, grade)
      );
    } catch (error) {
      console.log(error);
      console.log('error in updating course letter grade');
    }
  };

  const editCourseHandler = async (courseName, numCredits) => {
    try {
      let data = {};
      if (props.semester.isNew) {
        data = {
          name: courseName,
          credit: numCredits,
          letterGrade: editingCourse.letterGrade,
        };
      } else {
        data = {
          name: courseName,
          credit: numCredits,
          letterGrade: editingCourse.letterGrade,
        };
      }

      const response = await axiosInstance.put(
        `courses/${editingCourse.uuid}`,
        data
      );

      dispatch(editCourse(response.data, props.semester.uuid));
      dispatch(addCourseToSemester(response.data, props.semester.uuid));
      setSemGPA(-1);
      setNewCourse('');
      setEditingCourse('');
      setOpenEditCourseDialog(false);
      props.setShowSuccessToast(true);
      setTimeout(() => {
        props.setShowSuccessToast(false);
      }, 3000);
      return response;
    } catch (error) {
      alert(`Error! ${error.message}`);
      throw error;
    }
  };

  return (
    <>
      <div class="group relative p-4 pb-10 border rounded-lg transition  duration-300 ease-in-out hover:bg-gray-200 hover:border-gray-400">
        <div className="flex items-center mb-3 ">
          <div class="font-bold text-sm sm:text-lg md:text-2xl ">
            {props.semester.name}
          </div>
          <button
            class=" text-xs py-0.5 sm:ml-2  sm:px-3 sm:py-1  sm:leading-6 w-auto ml-2 sm:text-sm md:text-base leading-4  rounded-md bg-indigo-600 px-3 py-0 font-semibold sm:leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => setOpenCourseDialog(true)}
          >
            Add course
          </button>
        </div>
        <div className="absolute bottom-0 right-2 opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out">
          <DeleteOutlinedIcon
            onClick={() => setSemesterDelModalOpen(true)}
            className="mb-2 w-8 h-8 cursor-pointer text-red-500"
            // sx={{ cursor: 'pointer', color: 'red' }}
          />
        </div>

        {semesterDelModalOpen && (
          <Modal
            dialogTitle={`Delete ${props.semester.name}`}
            dialogMessage={`Are you sure you want to delete the semester: ${props.semester.name}? This would also delete all the study stats associated with the deleted items.`}
            dialogConfirmMessage="Delete"
            dialogConfirmAction={() => {
              handleAgreeToDeleteSem(
                props.deleteSemesterHandler,
                props.semester
              );
            }}
            dialogCancelAction={() => setSemesterDelModalOpen(false)}
          />
        )}

        {courses.length !== 0 && (
          <ul className="mt-5">
            {courses.map((course, idx) => (
              <div key={idx} className="flex justify-between">
                <li key={idx} className="md:mb-2 flex">
                  <span
                    title={course.name}
                    onClick={() => {
                      viewDetailsHandler(course.uuid, course.name);
                    }}
                    className="hover:text-pink-500 font-medium py-2 mr-1 md:mr-3 text-xs sm:text-sm hover:cursor-pointer md:text-lg"
                  >
                    {course.name}
                  </span>

                  <button
                    class="mr-1 sm:mr-3"
                    //class="   md:text-lg sm:text-sm text-xs text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg sm:text-sm px-2 py-0.5 text-center ml-2 mb-2"
                    onClick={() => {
                      viewDetailsHandler(course.uuid, course.name);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="gold"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 sm:w-7 sm:h-7  sm:text-sm md:text-lg font-bold hover:text-indigo-500"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                      />
                    </svg>
                  </button>
                  <button
                    class="mr-1 sm:mr-3"
                    onClick={() => {
                      setEditingCourse(course);
                      setOpenEditCourseDialog(true);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="pink"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-5 h-5 sm:w-7 sm:h-7 hover:text-blue-500"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                      />
                    </svg>
                  </button>
                  <button
                    // className="  md:text-lg font text-xs text-white bg-gradient-to-br from-pink-500 to-orange-400 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 font-small rounded-lg sm:text-sm px-2 py-0.5 text-center ml-2 mb-2"
                    onClick={() => handleClickOpenDelCourseDialog(course.uuid)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="tomato"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-5 h-5 sm:w-7 sm:h-7 hover:shadow-sm"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </button>
                </li>

                <GradeDropdown
                  selectedGrade={course.letterGrade}
                  handleGradeDropDownChange={handleGradeDropDownChange}
                  course={course}
                  idx={idx}
                />

                {courseDelOpen[course.uuid] && (
                  <Modal
                    dialogTitle={`Delete ${course.name}`}
                    dialogMessage={`Are you sure you want to delete the course: ${course.name}?  This would also delete all the study stats associated with the course.`}
                    dialogConfirmMessage="Delete"
                    dialogConfirmAction={() => {
                      deleteCourse(course);
                      return handleCloseDelCourseDialog(course.uuid);
                    }}
                    dialogCancelAction={() =>
                      handleCloseDelCourseDialog(course.uuid)
                    }
                  />
                )}
              </div>
            ))}
          </ul>
        )}
        {courses.length > 0 && (
          <div className="text-sm sm:text-base md:text-lg mt-1 sm:mt-2 flex justify-end">
            <span className="font-semibold">
              Semester GPA:{' '}
              {semGPA === -1
                ? 'Please enter all course grades'
                : `${semGPA}/${outOfGpa.toFixed(1)}`}
            </span>
          </div>
        )}
        {courses.length === 0 && (
          <span className="text-sm sm:text-md md:text-lg">
            No courses added ☹
          </span>
        )}
        {openCourseDialog && (
          <CourseDialog
            makeRequest={addCourseHandler}
            title="Add Course"
            successBtnTxt="Add"
            closeModal={() => setOpenCourseDialog(false)}
          />
        )}
        {openEditCourseDialog && (
          <CourseDialog
            makeRequest={editCourseHandler}
            title="Edit Course"
            successBtnTxt="Save changes"
            editingCourse={editingCourse}
            closeModal={() => setOpenEditCourseDialog(false)}
          />
        )}
      </div>
    </>
  );
};

export default Semester;
