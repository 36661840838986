import {
  ADD_COURSE,
  EDIT_COURSE,
  DELETE_COURSE,
  SET_COURSES,
  SET_COURSE_LETTER_GRADE,
  RESET_COURSES,
  CLEAR_COURSES,
} from '../actions/courseActions';

const initialState = {};

const courseReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_COURSE: {
      const { semesterId, course } = action.payload;

      const existingCourses = state[semesterId] || [];

      return {
        ...state,
        [semesterId]: [...existingCourses, course],
      };
    }
    case CLEAR_COURSES: {
      return {};
    }
    case SET_COURSE_LETTER_GRADE: {
      const { semesterId, course, grade } = action.payload;

      const existingCourses = state[semesterId] || [];

      const updatedCourses = existingCourses.map((crs) => {
        if (crs.uuid === course.uuid) {
          return {
            ...course,
            letterGrade: grade,
          };
        }
        return crs;
      });

      return {
        ...state,
        [semesterId]: updatedCourses,
      };
    }
    case RESET_COURSES: {
      const { semesterId } = action.payload;

      return {
        ...state,
        [semesterId]: [],
      };
    }
    case EDIT_COURSE: {
      const { semesterId, course } = action.payload;

      const existingCourses = state[semesterId] || [];
      const existingCoursesCopy = existingCourses.slice();
      const updatedCourses = existingCoursesCopy.map((crs) => {
        if (crs.uuid === course.uuid) {
          course.letterGrade = crs.letterGrade;
          return course;
        } else {
          return crs;
        }
      });

      return {
        ...state,
        [semesterId]: updatedCourses,
      };
    }
    case DELETE_COURSE: {
      const { semesterId, course } = action.payload;

      const existingCourses = state[semesterId] || [];
      const updatedCourses = existingCourses.filter(
        (crs) => crs.uuid !== course.uuid
      );

      return {
        ...state,
        [semesterId]: updatedCourses,
      };
    }
    case SET_COURSES: {
      const { semester } = action.payload;
      return {
        ...state,
        [semester.uuid]: semester.courses,
      };
    }
    default:
      return state;
  }
};

export default courseReducer;
