import React, { useState, useEffect } from 'react';
import ApexCharts from 'apexcharts';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';

export const PieChart = ({ dailyStats, formattedDates }) => {
  const [courses, setCourses] = useState([]);
  const [timeStudied, setTimeStudied] = useState([]);

  useEffect(() => {
    const totalTimeStudiedPerDay = {};
    let totalTimeStudied = 0;

    for (const stat of dailyStats) {
      for (const studyRecord of stat) {
        let course = studyRecord.course;
        if (!course.name) {
          course = { uuid: crypto.randomUUID(), name: '* Unknown (not set)' };
        }
        const timeStudied = studyRecord.time_studied;

        // Ensure that the course is initialized in totalTimeStudiedPerDay
        if (!totalTimeStudiedPerDay[course.name]) {
          totalTimeStudiedPerDay[course.name] = 0;
        }

        // Add the time studied to the total for the course
        totalTimeStudiedPerDay[course.name] += timeStudied;

        totalTimeStudied += timeStudied;
      }
    }

    let courses = Object.keys(totalTimeStudiedPerDay);
    const orderedTotalTimeStudied = courses.map(
      (course) =>
        Number((totalTimeStudiedPerDay[course] / totalTimeStudied).toFixed(2)) *
        100
    );

    setTimeStudied(orderedTotalTimeStudied);

    setCourses(courses);

    // ApexCharts options and config
  }, []);

  useEffect(() => {
    const getChartOptions = () => {
      if (!courses.length || !timeStudied.length) {
        return {};
      }
      const dynamicColors = generateDynamicColors();
      return {
        series: !timeStudied.length ? [] : timeStudied,
        colors: dynamicColors,
        chart: {
          height: 280,
          width: 300,
          maxWidth: '50%',
          type: 'pie',
        },
        stroke: {
          colors: ['white'],
          lineCap: '',
        },
        plotOptions: {
          pie: {
            labels: {
              show: true,
            },
            size: '100%',
            dataLabels: {
              offset: -25,
            },
          },
        },
        labels: !courses.length ? [''] : courses,
        dataLabels: {
          enabled: true,
          style: {
            fontFamily: 'Inter, sans-serif',
          },
        },
        legend: {
          position: 'bottom',
          fontFamily: 'Inter, sans-serif',
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value + '%';
            },
          },
        },
        xaxis: {
          labels: {
            formatter: function (value) {
              return value + '%';
            },
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
      };
    };

    if (
      courses.length > 0 &&
      document.getElementById('pie-chart') &&
      typeof ApexCharts !== 'undefined'
    ) {
      const chart = new ApexCharts(
        document.getElementById('pie-chart'),
        getChartOptions()
      );
      chart.render();
    }
  }, [courses, timeStudied]);

  const generateDynamicColors = () => {
    const colorScale = scaleOrdinal(schemeCategory10);
    const colors = [];
    for (let i = 0; i < courses?.length; i++) {
      colors.push(colorScale(i));
    }

    return colors;
  };

  return (
    <div class=" max-w-sm w-full bg-white rounded-lg shadow   p-4 md:p-6">
      <div class="flex justify-between items-start w-full">
        <div class="flex-col items-center">
          <div class="flex items-center mb-1">
            <h5 class="text-lg sm:text-xl font-bold leading-none text-gray-900  mr-1">
              Time studied
            </h5>
            <svg
              data-popover-target="chart-info"
              data-popover-placement="bottom"
              class="w-3.5 h-3.5 text-gray-500  hover:text-gray-900  cursor-pointer ml-1"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm0 16a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm1-5.034V12a1 1 0 0 1-2 0v-1.418a1 1 0 0 1 1.038-.999 1.436 1.436 0 0 0 1.488-1.441 1.501 1.501 0 1 0-3-.116.986.986 0 0 1-1.037.961 1 1 0 0 1-.96-1.037A3.5 3.5 0 1 1 11 11.466Z" />
            </svg>
            <div
              data-popover
              id="chart-info"
              role="tooltip"
              class="absolute z-10 invisible inline-block text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 w-72"
            >
              <div class="p-3 space-y-2">
                <h3 class="font-semibold text-gray-900">
                  Activity growth - Incremental
                </h3>
                <p>
                  Report helps navigate cumulative growth of community
                  activities. Ideally, the chart should have a growing trend, as
                  stagnating chart signifies a significant decrease of community
                  activity.
                </p>
                <h3 class="font-semibold text-gray-900">Calculation</h3>
                <p>
                  For each date bucket, the all-time volume of activities is
                  calculated. This means that activities in period n contain all
                  activities up to period n, plus the activities generated by
                  your community in period.
                </p>
                <a
                  href="#"
                  class="flex items-center font-medium text-blue-600 :text-blue-500 hover:text-blue-700 hover:underline"
                >
                  Read more{' '}
                  <svg
                    class="w-2 h-2 ml-1.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                </a>
              </div>
              <div data-popper-arrow></div>
            </div>
          </div>
          <button
            id="dateRangeButton"
            data-dropdown-toggle="dateRangeDropdown"
            data-dropdown-ignore-click-outside-class="datepicker"
            type="button"
            class="inline-flex items-center text-blue-700 font-medium hover:underline"
          >
            {formattedDates[0]} - {formattedDates[formattedDates.length - 1]}
          </button>
          <div
            id="dateRangeDropdown"
            class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-80 lg:w-96"
          >
            <div class="p-3" aria-labelledby="dateRangeButton">
              <div
                date-rangepicker
                datepicker-autohide
                class="flex items-center"
              >
                <div class="relative">
                  <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      class="w-4 h-4 text-gray-500"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                    </svg>
                  </div>
                  <input
                    name="start"
                    type="text"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 "
                    placeholder="Start date"
                  />
                </div>
                <span class="mx-2 text-gray-500 ">to</span>
                <div class="relative">
                  <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      class="w-4 h-4 text-gray-500 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                    </svg>
                  </div>
                  <input
                    name="end"
                    type="text"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    placeholder="End date"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-end items-center">
          {/* <button
            id="widgetDropdownButton"
            data-dropdown-toggle="widgetDropdown"
            data-dropdown-placement="bottom"
            type="button"
            class="inline-flex items-center justify-center text-gray-500 w-8 h-8  hover:bg-gray-100  focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm"
          >
            <svg
              class="w-3.5 h-3.5 text-gray-800 "
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 16 3"
            >
              <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
            </svg>
            <span class="sr-only">Open dropdown</span>
          </button> */}
          <div
            id="widgetDropdown"
            class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
          >
            <ul
              class="py-2 text-sm text-gray-700 "
              aria-labelledby="widgetDropdownButton"
            >
              <li>
                <a
                  href="#"
                  class="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  <svg
                    class="w-3 h-3 mr-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 21 21"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M7.418 17.861 1 20l2.139-6.418m4.279 4.279 10.7-10.7a3.027 3.027 0 0 0-2.14-5.165c-.802 0-1.571.319-2.139.886l-10.7 10.7m4.279 4.279-4.279-4.279m2.139 2.14 7.844-7.844m-1.426-2.853 4.279 4.279"
                    />
                  </svg>
                  Edit widget
                </a>
              </li>
              <li>
                <a
                  href="#"
                  class="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  <svg
                    class="w-3 h-3 mr-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 18 20"
                  >
                    <path d="M17 4h-4V2a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v2H1a1 1 0 0 0 0 2h1v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6h1a1 1 0 1 0 0-2ZM7 2h4v2H7V2Zm1 14a1 1 0 1 1-2 0V8a1 1 0 0 1 2 0v8Zm4 0a1 1 0 0 1-2 0V8a1 1 0 0 1 2 0v8Z" />
                  </svg>
                  Delete widget
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="py-6" id="pie-chart"></div>

      <div class="grid grid-cols-1 items-center border-gray-200 border-t  justify-between">
        <div class="flex justify-between items-center pt-5">
          {/* <button
            id="dropdownDefaultButton"
            data-dropdown-toggle="lastDaysdropdown"
            data-dropdown-placement="bottom"
            class="text-sm font-medium text-gray-500 hover:text-gray-900 text-center inline-flex items-center"
            type="button"
          > */}
          <div class="text-sm font-medium text-gray-500 hover:text-gray-900 text-center inline-flex items-center">
            {' '}
            Last 7 days
          </div>

          {/* <svg
              class="w-2.5 m-2.5 ml-1.5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 4 4 4-4"
              />
            </svg>
          </button> */}
          <div
            id="lastDaysdropdown"
            class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
          >
            <ul
              class="py-2 text-sm text-gray-700"
              aria-labelledby="dropdownDefaultButton"
            >
              <li>
                <a
                  href="#"
                  class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  Yesterday
                </a>
              </li>
              <li>
                <a
                  href="#"
                  class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  Today
                </a>
              </li>
              <li>
                <a href="#" class="block px-4 py-2 hover:bg-gray-100 ">
                  Last 7 days
                </a>
              </li>
              <li>
                <a
                  href="#"
                  class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  Last 30 days
                </a>
              </li>
              <li>
                <a href="#" class="block px-4 py-2 hover:bg-gray-100 ">
                  Last 90 days
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
