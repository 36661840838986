import React, { createContext, useState, useEffect } from 'react';
import { axiosInstance, getUserId } from '../../axiosApi';
import { formatTime } from '../../utils/timerUtils';

let timerWorker = new Worker(new URL('../../timerWorker', import.meta.url));
export const TimerContext = createContext();

const TimerContextProvider = ({ children }) => {
  const [timerOn, setTimerOn] = useState(false);
  const [timerPaused, setTimerPaused] = useState(false);
  const [timerMode, setTimerMode] = useState('stopwatch'); //Todo: timer or stopwatch (make it enum)
  const [time, setTime] = useState(0);
  const [forComponent, setForComponent] = useState({});
  const [forCourse, setForCourse] = useState({});
  const [timerStartTime, setTimerStartTime] = useState(0);
  const [timers, setTimers] = useState({});
  const [description, setDescription] = useState('');
  const [persisted, setPersisted] = useState(false);
  const [isPersisting, setIsPersisting] = useState(false);
  const [showStreaksNotif, setShowStreaksNotif] = useState(false);

  const [audio] = useState(new Audio('/timer-alarm.mp3'));

  // Add other timer-related state and functions here
  const persistTime = async (timeToAdd) => {
    const { hours, minutes, seconds } = formatTime(timeToAdd);
    const statData = { description };
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    setIsPersisting(true);
    let response;
    try {
      response = await axiosInstance.put(`courses/components/studyTime/`, {
        component_id: forComponent?.uuid,
        course_id: forCourse?.uuid,
        user_id: getUserId(),
        additional_hours: hours,
        additional_mins: minutes,
        additional_secs: seconds,
        timezone: userTimeZone,
        statData,
      });

      setForComponent(null);
      setForCourse(null);
      if (response.data.is_streak_incremented) {
        setShowStreaksNotif(true);
        setInterval(() => {
          setShowStreaksNotif(false);
        }, 3000);
      }
    } catch (error) {
      alert(`Error! ${error.message}`);
      throw error;
    }

    setIsPersisting(false);
    setPersisted(true);
    return response;
  };

  useEffect(() => {
    return () => {
      timerWorker.terminate();
    };
  }, []);
  useEffect(() => {
    if (timerOn && timerWorker) {
      timerWorker.terminate();
      timerWorker = new Worker(new URL('../../timerWorker', import.meta.url));
      const dataToSend = {
        message: 'start',
        mode: timerMode,
        offset: time,
      };
      // timer has been turned on
      timerWorker.postMessage(dataToSend);
      timerWorker.onmessage = (e) => {
        const updatedTime = e.data;
        if (timerMode === 'timer' && updatedTime === 0) {
          stopTimer(true);
          setTimerOn(false);
          audio.play(); //play audio
        }

        setTime(updatedTime);
      };

      setTimers(() => ({
        [forComponent?.uuid]: true,
        [forCourse?.uuid]: true,
      }));
    } else {
      //timer has been turned off
      if (!timerPaused) {
        if (forCourse || forComponent) {
          setTimers(() => ({
            [forComponent?.uuid]: false,
            [forCourse?.uuid]: false,
          }));
        }
      }
    }
  }, [timerOn]);

  const startTimer = (selectedMode, timerHours, timerMinutes, timerSeconds) => {
    setTimerMode(selectedMode);
    if (selectedMode === 'timer') {
      let totalSeconds = timerHours * 3600 + timerMinutes * 60 + timerSeconds;
      setTime(totalSeconds);
      setTimerStartTime(totalSeconds);
    } else {
      setTime(0);
    }

    setTimerOn(true);
    setTimerPaused(false);
  };

  // returns the timer api response for the component that the time was added for. (need to add another method or make this abstract when no component is associated.)
  const stopTimer = async (timerEnded = false) => {
    timerWorker.postMessage('stop');
    let timeToPersist = time;

    if (timerMode === 'timer') {
      timeToPersist = timerStartTime - time;
    }

    if (timerEnded) {
      timeToPersist = timerStartTime;
    }

    let response;
    if (timeToPersist !== 0) {
      response = await persistTime(timeToPersist);
    }

    setTimerOn(false);
    setTimerPaused(false);
    setTime(0);
    timerWorker.terminate();
    return response;
  };

  const resumeTimer = () => {
    setTimerOn(true);
    setTimerPaused(false);
  };

  const pauseTimer = () => {
    timerWorker.postMessage('pause');
    setTimerOn(false);
    setTimerPaused(true);
  };

  const resetTimer = () => {
    timerWorker.postMessage('reset');
    timerWorker.terminate();
    setTimerPaused(false);
    setTimerOn(false);
    setTime(0);

    setForComponent(null);
    setForCourse(null);
  };

  return (
    <TimerContext.Provider
      value={{
        time,
        timerOn,
        timerPaused,
        timers,
        forComponent,
        forCourse,
        description,
        startTimer,
        pauseTimer,
        resetTimer,
        resumeTimer,
        stopTimer,
        setTimerStartTime,
        setForComponent,
        setForCourse,
        setDescription,
        persisted,
        setPersisted,
        isPersisting,
        timerMode,
        showStreaksNotif,
      }}
    >
      {children}
    </TimerContext.Provider>
  );
};

export default TimerContextProvider;
