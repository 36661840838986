import GradePointSettings from './GradePointSettings';
import InfoAlert from './infoAlert';
import { useState, useEffect } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { axiosInstance } from '../axiosApi';
import NewNavBar from './NewNavBar';
function Settings() {
  const [successAlert, setSuccessAlert] = useState('');
  const [errorAlert, setErrorAlert] = useState('');
  const [isEmailNotif, setIsEmailNotif] = useState(false);
  const history = useHistory();
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    document.title = 'Settings | Course Tracker';
    // fetch email notif preference
    const fetchEmailPreference = async () => {
      try {
        //console.log(`user id = ${getUserId()}`);'
        // debugger;
        const response = await axiosInstance.get(
          `user/emailNotification/${localStorage.getItem('user_id')}`
        );
        setIsEmailNotif(response.data.preference);

        return response;
      } catch (error) {
        alert(`Error! ${error.message}`);
        //console.log(error);
        throw error;
      }
    };
    fetchEmailPreference();
  }, []);

  const handleUpdateEmailNotif = async () => {
    try {
      const response = await axiosInstance.patch(
        `user/emailNotification/${localStorage.getItem('user_id')}`
      );
      setSuccessAlert('Email notification preference updated');
      scrollToTop();
      setIsEmailNotif(response.data.preference);
      setTimeout(() => {
        setSuccessAlert('');
      }, 1000);
      return response;
    } catch (error) {
      //console.log(error);
      const res = error.response.data;
      setErrorAlert(res);
      scrollToTop();
      setTimeout(() => {
        setErrorAlert('');
      }, 3000);
    }
  };

  const showSuccessAlert = () => {
    setSuccessAlert('Changes updated successfully!');
    setTimeout(() => {
      setSuccessAlert('');
    }, 3000);
    scrollToTop();
  };

  const showErrorAlert = (message) => {
    setErrorAlert(message);
    setTimeout(() => {
      setErrorAlert('');
    }, 3000);
    scrollToTop();
  };
  return (
    <>
      <NewNavBar handleGoBack={() => history.goBack()} />
      <div className="w-full absolute top-5 ">
        {errorAlert && (
          <InfoAlert
            message={errorAlert}
            close={() => {
              setErrorAlert('');
            }}
            type="error"
          />
        )}
        {successAlert && (
          <InfoAlert message={successAlert} close={() => setSuccessAlert('')} />
        )}
      </div>
      <div className="mx-4 min-h-screen max-w-screen-xl sm:mx-8 xl:mx-auto">
        <div className="mt-5 flex">
          <h1 className="border-b py-6 text-4xl font-semibold">Settings</h1>
        </div>
        {/* <input
            className="peer hidden"
            type="checkbox"
            name="select-1"
            id="select-1"
          />
          <label
            htmlFor="select-1"
            className="flex w-full cursor-pointer select-none rounded-lg border p-2 px-3 text-sm text-gray-700 ring-blue-700 peer-checked:ring"
          >
            Notifications
          </label> */}
        {/* ... Continue converting the rest of the HTML */}
        {/* <div class="col-span-8  rounded-xl bg-gray-50 px-8 shadow"> */}
        <h1 class="py-2 text-2xl font-semibold">Notification settings</h1>
        <p class="font- text-slate-600">
          <div class="mt-7 flex">
            <div class="flex items-center h-5">
              <input
                id="helper-checkbox"
                aria-describedby="helper-checkbox-text"
                type="checkbox"
                checked={isEmailNotif}
                value=""
                onClick={handleUpdateEmailNotif}
                class="w-4 h-4 text-blue-600  border-gray-300 rounded focus:ring-blue-500 focus:ring-blue-600 ring-offset-gray-800 focus:ring-2  border-gray-600"
              />
            </div>
            <div class="ml-2 text-sm">
              <label for="helper-checkbox" class="font-medium lg:text-base ">
                Email notifications
              </label>
              <p
                id="helper-checkbox-text"
                class="text-xs font-normal text-gray-700"
              >
                Receive email notifcations to update marks and exam reminders.
              </p>
            </div>
          </div>
        </p>
        <h1 class="mt-5 py-2 text-2xl font-semibold">
          Grade point scale settings
        </h1>
        {/* </div> */}
        <GradePointSettings
          showErrorAlert={showErrorAlert}
          showSuccessAlert={showSuccessAlert}
        />
      </div>
    </>
  );
}

export default Settings;
