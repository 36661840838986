import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
// import Alert from '@mui/material/Alert';
// import CircularProgress from '@mui/material/CircularProgress';
// import Box from '@mui/material/Box';
import { axiosInstance } from '../axiosApi';
// import jwt_decode from 'jwt-decode';
// import TextField from '@mui/material/TextField';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import Button from '@mui/material/Button';

import ButtonLoader from './ButtonLoader';
import { useAuthAlert } from '../Contexts/authAlertContext';
import InfoAlert from './infoAlert';

const Signup = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // const [open, setOpen] = useState(false);
  const [, setShowSuccessReset] = useState(false);
  // const [resetEmail, setResetEmail] = useState('');

  const { setSignupSuccessAlert } = useAuthAlert();

  const history = useHistory();
  const location = useLocation();

  if (localStorage.getItem('access_token')) history.push('/home');
  useEffect(() => {
    setShowSuccessReset(true);
  }, [location]);

  useEffect(() => {
    document.title = 'Sign up | Course Tracker';
  }, []);
  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    }
  }, [errorMessage]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSignup = async (event) => {
    event.preventDefault();
    // debugger;
    setIsLoading(true);
    try {
      await axiosInstance.post('user/create/', {
        email: email,
        password: password,
        url: `https://${window.location.hostname}/verify/email`,
      });
      setIsLoading(false);
      setSignupSuccessAlert();
      history.push('/signin');
    } catch (error) {
      setIsLoading(false);
      const res = error.response.data;
      let message = '';
      Object.keys(res).map((keyName) => {
        message += `${keyName.toUpperCase()}: ${res[keyName]}\n`;
        return res[keyName];
      });
      setErrorMessage(message);
    }
  };

  return (
    <>
      <div className="w-full absolute top-5">
        {errorMessage && (
          <InfoAlert
            message={errorMessage}
            close={() => setErrorMessage('')}
            type="error"
          />
        )}
      </div>
      <div className="flex min-h-screen items-center  flex-1 flex-col justify-center px-2 py-4 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-12 sm:h-20 w-auto"
            src="/courseTrackerIcon.png"
            alt="Course Tracker"
          />
          <h2 className="mt-2 text-center  text-xl sm:text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Register account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" action="#" method="POST">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  onChange={handleEmailChange}
                  required
                  className="block w-full rounded-md border-0 py-1 leading-4 sm:py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  onChange={handlePasswordChange}
                  required
                  className="block w-full rounded-md border-0 py-1 leading-4 sm:py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="confirmPassword"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Confirm password
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  autoComplete="current-password"
                  onChange={handlePasswordChange}
                  required
                  className="block w-full rounded-md border-0 py-1 leading-4 sm:py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                onClick={handleSignup}
                className="flex w-full items-center justify-center rounded-md bg-indigo-600 px-3 py-1 sm:py-1.5 text-xs sm:text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sign up
                {isLoading && <ButtonLoader />}
              </button>
            </div>
          </form>

          <p className="text-xs sm:text-sm mt-5 sm:mt-10 text-center text-sm text-gray-500">
            Have an account?{' '}
            <Link
              to="/signin"
              className=" text-xs sm:text-sm font-semibold leading-4 sm:leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Sign in
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default Signup;
