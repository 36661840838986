import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Prompt } from 'react-router-dom';
import {
  calcWeight,
  calcGradeReceived,
  calcTotalGoalGradeTillNow,
  calcTotalWeightCompleted,
} from './utils';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import './CourseDetails.css';
import NewNavBar from '../NewNavBar';
import { axiosInstance } from '../../axiosApi';
import StudyDescriptionDialog from '../StudyDescriptionDialog';
import CourseTable from './CourseTable';
import { formatTime } from '../../utils/timerUtils';
import { AddComponentForm } from './AddComponentForm';
import TimerDisplay from '../TimerDisplay';
import TimerActions from '../TimerActions';
import { TimerContext } from '../Contexts/TimerContextProvider';
import Toast from '../Toast';

const CourseDetails = () => {
  const params = useParams();
  const history = useHistory();

  const [components, setComponents] = useState([]);
  const [showAddComponentForm, setShowAddComponentForm] = useState(false);
  const [options, setOptions] = useState([]);
  // const [hasFinishedCourse, setHasFinishedCourse] = useState(false);
  const [isNotPossibleFeedback, setIsNotPossibleFeedback] = useState(false);
  const [hasUnknownComponent, setHasUnknownComponent] = useState(false);
  const [feedbackAvailable, setFeedbackAvailable] = useState(false);

  const [courseName, setCourseName] = useState('');

  const [totalWeightCompleted, setTotalWeightCompleted] = useState(0);
  const [showSuccessToast, setShowSuccessToast] = useState(false);

  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const {
    time,
    timerOn,
    resetTimer,
    timerPaused,
    forComponent,
    stopTimer,
    setForComponent,
    persisted,
    setPersisted,
    timerMode,
    showStreaksNotif,
  } = React.useContext(TimerContext);

  const isCourseCompleted = totalWeightCompleted >= 100;

  useEffect(() => {
    return () => {
      resetTimer();
    };
  }, []);

  const fetchCourseComponents = async () => {
    try {
      const response = await axiosInstance.get(
        `courses/components/${params.id}`
      );

      const fetchedCourseName = response.data.courseName;

      setCourseName(fetchedCourseName);
      setComponents(response.data.components);

      //Update page title
      if (fetchedCourseName) {
        document.title = `${fetchedCourseName.toUpperCase()} | Course Tracker`;
      } else {
        document.title = `Course details | Course Tracker`;
      }

      return response;
    } catch (error) {
      alert(`Error! ${error.message}`);
      document.title = `Course details | Course Tracker`;
      throw error;
    }
  };

  useEffect(() => {
    fetchCourseComponents();
  }, [params.id]);

  useEffect(() => {
    const { hours, minutes, seconds } = formatTime(time);

    if (timerOn) {
      document.title = `${String(hours).padStart(2, '0')}:${String(
        minutes
      ).padStart(2, '0')}
    :${String(seconds).padStart(
      2,
      '0'
    )} - ${courseName.toUpperCase()} | Course Tracker`;
    } else {
      document.title = `${courseName.toUpperCase()} | Course Tracker`;
    }

    setHours(hours);
    setMinutes(minutes);
    setSeconds(seconds);
  }, [time]);

  useEffect(() => {
    updateOptions();
    setTotalWeightCompleted(calcTotalWeightCompleted(components));
    checkIfFeedbackAvailable();
  }, [components, setTotalWeightCompleted, setOptions, setFeedbackAvailable]);

  useEffect(() => {}, [options]);

  useEffect(() => {
    if (persisted && timerMode === 'timer') {
      fetchCourseComponents();
      setPersisted(false);
    }
  }, [persisted]);

  const isPossibleToPredict = () => {
    for (let component of components) {
      if (component.goal_grade <= 0) return false;
    }
    return true;
  };

  const checkIfFeedbackAvailable = () => {
    for (let component of components) {
      if (component.grade_received > 0) {
        setFeedbackAvailable(true);
        return;
      }
    }
    setFeedbackAvailable(false);
  };

  const calcPredictedGrade = () => {
    let currentScore = calcGradeReceived(components);

    let futureComponents = components.filter(
      (component) => component.grade_received < 0 && component.goal_grade >= 0
    );
    for (let futureComponent of futureComponents) {
      currentScore +=
        futureComponent.goal_grade * (futureComponent.weight / 100);
    }
    return currentScore;
  };

  const hasCompWithoutGrade = () => {
    for (let component of components) {
      if (component.grade_received < 0 && component.goal_grade > 0) return true;
    }
    //setMessageWhenNoneLeft();
    return false;
  };

  const sortArrByDate = (comps) => {
    comps.sort(function (a, b) {
      return new Date(a.due_date) - new Date(b.due_date);
    });
    return comps;
  };

  const updateOptions = () => {
    setOptions([]);
    let neededGrade = -1;
    let totalGradeReceivedSoFar = 0;
    let totalGoalGradeSoFar = 0;
    let totalWeightDoneSoFar = 0;

    let doneComponents = components.filter(
      (component) => component.grade_received >= 0 && component.goal_grade >= 0
    );
    for (let doneComponent of doneComponents) {
      totalGradeReceivedSoFar +=
        (doneComponent.weight / 100) * doneComponent.grade_received;
      totalGoalGradeSoFar +=
        (doneComponent.weight / 100) * doneComponent.goal_grade;
      totalWeightDoneSoFar += doneComponent.weight;
    }

    let toDoComponents = components.filter(
      (component) => component.grade_received < 0 && component.goal_grade > 0
    );

    for (let i = 0; i < toDoComponents.length; i++) {
      let component = toDoComponents[i];
      totalGoalGradeSoFar += (component.weight / 100) * component.goal_grade;
      totalWeightDoneSoFar += component.weight;
      neededGrade =
        (totalGoalGradeSoFar / 100 - totalGradeReceivedSoFar / 100) /
        (component.weight / 100);
      if (neededGrade <= 1 && neededGrade > 0) {
        setOptions((prev) => {
          return [...prev, { name: component.name, neededGrade: neededGrade }];
        });
        return;
      } else if (neededGrade > 1) {
        setOptions((prev) => {
          return [...prev, { name: component.name, neededGrade: 1 }];
        });

        //Because we will assume we just took this course so calculate for next
        totalGradeReceivedSoFar += 100 * (component.weight / 100);

        //Course completed and not able reach goal
        if (totalWeightDoneSoFar === 100) {
          setIsNotPossibleFeedback(true);
          setOptions([]);
          return;
        }

        if (i == toDoComponents.length - 1) {
          setHasUnknownComponent(true);
          return;
        }
      } else if (neededGrade == 1) {
        setOptions((prev) => {
          return [...prev, { name: component.name, neededGrade: 1 }];
        });
        return;
      }
    }
  };

  let timeColor = timerPaused ? 'red' : timerOn ? 'green' : 'black';

  const addTime = async () => {
    let response;
    try {
      response = await stopTimer();
    } catch (error) {
      alert(`Error! ${error.message}`);
      throw error;
    }

    setComponents((prevComponents) => {
      let result = [];
      for (let component of prevComponents) {
        if (component.uuid === forComponent.uuid) {
          component.total_time_studied_hours =
            response.data.component.total_time_studied_hours;
          component.total_time_studied_minutes =
            response.data.component.total_time_studied_minutes;
          component.total_time_studied_seconds =
            response.data.component.total_time_studied_seconds;
          result.push({ ...component });
        } else result.push(component);
      }

      setShowSuccessToast(true);
      setInterval(() => {
        setShowSuccessToast(false);
      }, 3000);

      return sortArrByDate(result);
    });
  };

  //opens up the timer dialog for adjusting timer
  const setupTimer = (component) => {
    setShowDescriptionModal(true);
    setForComponent(component);
  };

  let listOfLi = options.map((option, idx) => {
    if (idx === options.length - 1)
      return (
        <li key={idx}>
          → Atleast score{' '}
          <span className="option-needed-grade">
            {(option.neededGrade * 100).toFixed(2)}%
          </span>{' '}
          on <span className="option-name">{option.name}</span>
        </li>
      );
    else
      return (
        <li key={idx}>
          → Atleast score{' '}
          <span className="option-needed-grade">
            {(option.neededGrade * 100).toFixed(2)}%
          </span>{' '}
          on <span className="option-name">{option.name}</span> and{' '}
        </li>
      );
  });

  const handleGoBack = () => {
    history.goBack();
  };

  const timerInfo = {
    hours: hours,
    minutes: minutes,
    seconds: seconds,
    timeColor: timeColor,
  };

  return (
    <div>
      <NewNavBar handleGoBack={handleGoBack} timerOn={timerOn} />
      {!showStreaksNotif && showSuccessToast && (
        <Toast text="Time added to stats" />
      )}
      {showStreaksNotif && <Toast text="Great stuff! Streaks 🔥 +1" />}
      {showDescriptionModal && (
        <StudyDescriptionDialog
          onlyDescription={true}
          closeModal={() => {
            setShowDescriptionModal(false);
          }}
        />
      )}
      <div className="main-title">
        {(timerOn || timerPaused) && (
          <div className="flex justify-center items-center">
            <TimerDisplay />
            <TimerActions
              addTime={addTime}
              setupTimer={() => setShowDescriptionModal(true)}
            />
          </div>
        )}
        <h1 className="text-center font-medium text-xs md:text-md lg:text-lg ">
          {courseName.toUpperCase()}{' '}
          {isCourseCompleted ? <DoneOutlineIcon sx={{ color: 'green' }} /> : ''}
        </h1>
        <button
          className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm md:text-bg lg:text-lg px-2 py-2 text-center mt-2 mb-6"
          onClick={() => setShowAddComponentForm(true)}
        >
          Add component
        </button>
      </div>
      <div className="mt-5 ml-4 sm:mx-10">
        <CourseTable
          components={components}
          setComponents={setComponents}
          timerInfo={timerInfo}
          setupTimer={setupTimer}
          setFeedbackAvailable={setFeedbackAvailable}
          addTime={addTime}
        />

        <span className="text-md md:text-lg lg:text-xl font-semibold bg-clip-text text-transparent bg-gradient-to-br from-pink-500 to-orange-400">
          {' '}
          Feedback{' '}
        </span>
        {components.length > 0 && (
          <>
            {(isCourseCompleted || feedbackAvailable) && (
              <p className="mt-2">
                You have scored{' '}
                <span className="feedback-total">
                  {calcGradeReceived(components).toFixed(2)}%
                </span>{' '}
                of the{' '}
                <span className="feedback-total">
                  {calcTotalWeightCompleted(components)}%
                </span>{' '}
                of the course that you <strong>received</strong> a grade for.
              </p>
            )}
            {!isCourseCompleted && !feedbackAvailable && (
              <p className="mt-2">
                Enter a grade received for a component to start getting
                feedbacks!
              </p>
            )}
          </>
        )}
        {components.length === 0 && (
          <>
            <p>
              {' '}
              Please insert components in the table above to get feedbacks.
            </p>
          </>
        )}
        {!isCourseCompleted && (
          <div>
            {calcGradeReceived(components).toFixed(2) <
              calcTotalGoalGradeTillNow(components).toFixed(2) && (
              <div>
                {/* <p> Your goal grade went down by {(calcGoalGrade() - calcGradeReceived()).toFixed(2)}%.</p> */}
                <h3 className="text-bold mt-2 mb-2 text-red-600">
                  {' '}
                  Looks like you have fallen behind ☹
                </h3>
                {!isNotPossibleFeedback && (
                  <p className="mb-2">
                    {' '}
                    To be back on track with your goal, you need to:{' '}
                  </p>
                )}
                {!isNotPossibleFeedback &&
                  hasCompWithoutGrade &&
                  options.length > 0 && (
                    <div>
                      <ul className="ml-4">{listOfLi} </ul>
                      <p className="mt-2 italic">
                        Note that this is just one possible combination!
                      </p>
                    </div>
                  )}
                {/* {hasUnknownComponent && <p> <span>Recommendation: Your components do not add up to 100%, so please add other components.</p>} */}
                {isNotPossibleFeedback && (
                  <p className="">
                    Sorry, looks like it won't be possible to reach your
                    original goal for this course!
                  </p>
                )}
              </div>
            )}
          </div>
        )}

        {!isCourseCompleted && (
          <div>
            {calcGradeReceived(components) >
              calcTotalGoalGradeTillNow(components) && (
              <div>
                {/* <p>Your goal grade went up by {(calcGradeReceived() - calcGoalGrade()).toFixed(2)}%.</p> */}
                <h3 className="better-than-goal-txt">
                  You have exceeded your goals so far!
                </h3>
              </div>
            )}

            {components.length > 0 &&
              feedbackAvailable &&
              calcGradeReceived(components).toFixed(2) ===
                calcTotalGoalGradeTillNow(components).toFixed(2) && (
                <div>
                  <h3 className="on-track-with-goal-txt">
                    You are on track with your goal, keep up the great work!
                  </h3>
                </div>
              )}

            <h3 className=" lg:mt-5 mt-2 font-bold">
              <span className=" text-md md:text-lg lg:text-xl  bg-clip-text text-transparent bg-gradient-to-br from-pink-500 to-orange-400">
                Predicted Score:
              </span>
            </h3>

            <div className="predicted-score-content">
              {components.length > 0 && isPossibleToPredict() && (
                <p className="mb-5 mt-2">
                  Assuming you get your <strong>goal</strong> grade for the{' '}
                  <strong>rest</strong> of the components, you will end up with
                  a final score of{' '}
                  <span className="feedback-total">
                    {calcPredictedGrade().toFixed(2)} /{' '}
                    {calcWeight(components).toFixed(2)}%
                  </span>
                  .
                </p>
              )}
              {components.length > 0 && !isPossibleToPredict() && (
                <p class="mt-2">
                  Not possible to predict since <strong>all</strong> components
                  do not have a <strong>goal</strong> grade specified.
                </p>
              )}
              {components.length === 0 && (
                <p>
                  Please insert components in the table above to get predicted
                  score.
                </p>
              )}
            </div>
          </div>
        )}

        {calcWeight(components) < 100 && (
          <div className="insert-rem-components-txt mb-5">
            {' '}
            <span className="note">Note:</span> To get a better experience,
            please fill out <strong>goal grades</strong> for{' '}
            <strong>all</strong> components of the course. Currently, you have
            provided only <strong>{calcWeight(components).toFixed(2)}%</strong>{' '}
            of the components.
          </div>
        )}

        {isCourseCompleted && (
          <p className="font-bold mt-2 mb-2">
            {' '}
            Congratulations! You are done with the course!
          </p>
        )}
      </div>

      <Prompt
        when={timerOn || timerPaused}
        message="You have an active timer. Are you sure you want to leave this page?"
      />

      {showAddComponentForm && (
        <AddComponentForm
          isOpen={showAddComponentForm}
          handleClose={() => setShowAddComponentForm(false)}
          components={components}
          setComponents={setComponents}
          setFeedbackAvailable={setFeedbackAvailable}
        />
      )}
    </div>
  );
};

export default CourseDetails;
