import React, { useState } from 'react';
import BaseForm from '../BaseForm.js';
import { axiosInstance } from '../../axiosApi';
import TextField from '@mui/material/TextField';
import { useParams } from 'react-router-dom';
import { nameAlreadyExists } from './utils.js';

export const AddComponentForm = ({
  components,
  setComponents,
  setFeedbackAvailable,
  isOpen,
  handleClose,
}) => {
  const params = useParams();
  const [newComponent, setNewComponent] = useState({
    name: '',
    grade_received: -1,
    goal_grade: -1,
    weight: undefined,
    due_date: undefined,
  });
  const [isNameEmpty, setIsNameEmpty] = useState(false);
  const [isWeightEmpty, setIsWeightEmpty] = useState(false);
  const [isDueDateEmpty, setIsDueDateEmpty] = useState(false);

  const handleCancel = () => {
    setNewComponent({
      name: '',
      weight: '',
      grade_received: '',
      goal_grade: '',
      due_date: '',
    });
    setIsNameEmpty(false);
    setIsWeightEmpty(false);
    setIsDueDateEmpty(false);
    handleClose();
  };

  const dueDateFocusHanlder = (event) => {
    if (event.target.value === '') setIsDueDateEmpty(true);
    else setIsDueDateEmpty(false);
  };
  const gradeReceivedHandler = (event) => {
    if (event.target.value === '')
      setNewComponent((prev) => {
        return { ...prev, grade_received: event.target.value };
      });
    else
      setNewComponent((prev) => {
        return { ...prev, grade_received: parseFloat(event.target.value) };
      });
  };
  const expectedGradeHandler = (event) => {
    if (event.target.value === '')
      setNewComponent((prev) => {
        return { ...prev, goal_grade: event.target.value };
      });
    else
      setNewComponent((prev) => {
        return { ...prev, goal_grade: parseFloat(event.target.value) };
      });
  };

  const calcWeight = () => {
    let sum = 0;
    for (let component of components) {
      sum += component.weight;
    }
    return parseFloat(sum.toFixed(2));
  };

  const dueDateHandler = (event) => {
    setNewComponent((prev) => {
      return { ...prev, due_date: event.target.value };
    });
    setIsDueDateEmpty(false);
  };
  const componentNameFocusHandler = (event) => {
    if (event.target.value === '') setIsNameEmpty(true);
    else setIsNameEmpty(false);
  };

  const hasInputError = () => {
    let hasError = false;
    if (!newComponent.weight) {
      setIsWeightEmpty(true);
      hasError = true;
    } else {
      setIsWeightEmpty(false);
    }
    if (!newComponent.name || !newComponent.name.trim()) {
      setIsNameEmpty(true);
      hasError = true;
    } else {
      setIsNameEmpty(false);
    }
    if (!newComponent.due_date) {
      setIsDueDateEmpty(true);
      hasError = true;
    } else {
      setIsDueDateEmpty(false);
    }
    if (nameAlreadyExists(newComponent.name, components)) {
      hasError = true;
    }
    if (
      newComponent.grade_received > 100 ||
      newComponent.expected_grade > 100
    ) {
      hasError = true;
    }

    const weightLeft = parseFloat(Math.abs(100 - calcWeight()).toFixed(2));

    if (newComponent.weight > weightLeft) {
      alert('Total weight exceeds 100%, no can do!');
      hasError = true;
    }

    return hasError;
  };

  const handleAddComponent = async () => {
    if (hasInputError()) return;
    try {
      if (
        !('grade_received' in newComponent) ||
        newComponent.grade_received === ''
      ) {
        //console.log("grade_received is blank");
        newComponent.grade_received = -1;
      } else {
        setFeedbackAvailable(true);
      }
      if (!('goal_grade' in newComponent) || newComponent.goal_grade === '') {
        newComponent.goal_grade = -1;
      }
      const response = await axiosInstance.post(
        `courses/components/${params.id}`,
        { ...newComponent }
      );
      setComponents((prevComponents) =>
        sortArrByDate([response.data, ...prevComponents])
      );

      setNewComponent({
        name: '',
        weight: '',
        grade_received: '',
        goal_grade: '',
        due_date: '',
      });
      handleClose();
      return response;
    } catch (error) {
      alert(`Error! ${error.message}`);
      throw error;
    }
  };

  const sortArrByDate = (comps) => {
    comps.sort(function (a, b) {
      return new Date(a.due_date) - new Date(b.due_date);
    });
    return comps;
  };

  const maxWeightYouCanAdd = parseFloat(100 - calcWeight()).toFixed(2);

  const componentNameHandler = (event) => {
    if (event.target.value === '') setIsNameEmpty(true);
    else setIsNameEmpty(false);

    setNewComponent((prev) => {
      return { ...prev, name: event.target.value };
    });
  };
  const weightHandler = (event) => {
    if (event.target.value === '') setIsWeightEmpty(true);
    else setIsWeightEmpty(false);

    setNewComponent((prev) => {
      return { ...prev, weight: parseFloat(event.target.value) };
    });
  };

  function isPositiveInteger(str) {
    if (str === '0') return true;
    // Use a regular expression to check if the string is a strictly positive integer value
    // It checks for one or more digits without a sign
    const positiveIntegerRegex = /^\d+$/;
    return positiveIntegerRegex.test(str);
  }

  const containsMinutesOrSecondsError = (val) => {
    return (
      (val !== '' && !isPositiveInteger(val)) ||
      (val.length > 0 && val.startsWith('0')) ||
      val > 60
    );
  };

  const validTimeStr = (val) => {
    // Check if the string is empty or a valid positive integer without leading zeros
    const validHoursRegex = /^(0|[1-9]\d*)$/;
    return validHoursRegex.test(val);
  };

  const weightFocusHandler = (event) => {
    if (event.target.value === '') setIsWeightEmpty(true);
    else setIsWeightEmpty(false);
  };

  return (
    <div>
      <BaseForm
        isOpen={isOpen}
        title="Add Component"
        description="Leave the grade received section empty unless you have already received a mark for it."
        confirmButtonText="Add"
        handleSubmit={handleAddComponent}
        handleCancel={handleCancel}
      >
        <TextField
          error={
            isNameEmpty || nameAlreadyExists(newComponent?.name, components)
          }
          helperText={
            nameAlreadyExists(newComponent?.name, components)
              ? 'This name already exists'
              : 'Component name is mandatory'
          }
          margin="dense"
          id="Component Name"
          label="Component Name"
          placeholder="Assignment 1"
          type="text"
          value={newComponent?.name}
          fullWidth
          onChange={componentNameHandler}
          onFocus={componentNameFocusHandler}
          variant="standard"
        />
        <TextField
          error={
            isWeightEmpty ||
            newComponent?.weight > 100 ||
            newComponent?.weight > maxWeightYouCanAdd
          }
          helperText={
            newComponent?.weight > maxWeightYouCanAdd
              ? `Total weight of all courses exceeds 100%, max weight you can add is ${maxWeightYouCanAdd}%`
              : 'Weight is mandatory and has to be between 0 and 100 (inclusive)'
          }
          margin="dense"
          id="Weight"
          label="Weight (%)"
          type="number"
          step={1.0}
          value={newComponent?.weight}
          fullWidth
          placeholder="15"
          onChange={weightHandler}
          onFocus={weightFocusHandler}
          variant="standard"
        />
        <TextField
          error={newComponent?.grade_received > 100}
          helperText={'Grade received has to be between 0 and 100 (inclusive)'}
          margin="dense"
          id="Grade Received"
          label="Grade Received (%)"
          placeholder="80"
          type="number"
          step={1.0}
          value={
            newComponent?.grade_received === -1
              ? ''
              : newComponent?.grade_received
          }
          fullWidth
          onChange={gradeReceivedHandler}
          variant="standard"
        />
        <TextField
          error={newComponent?.goal_grade > 100}
          helperText={'Goal grade has to be between 0 and 100 (inclusive)'}
          autoFocus
          margin="dense"
          id="Goal Grade"
          label="Goal Grade (%)"
          type="number"
          step={1.0}
          value={
            newComponent?.goal_grade === -1 ? '' : newComponent?.goal_grade
          }
          placeholder="85"
          fullWidth
          onChange={expectedGradeHandler}
          variant="standard"
        />
        <TextField
          error={isDueDateEmpty}
          helperText={'Due date is mandatory'}
          margin="dense"
          id="Due date"
          label="Due date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={newComponent?.due_date?.slice(0, 10)}
          fullWidth
          onChange={dueDateHandler}
          onFocus={dueDateFocusHanlder}
          variant="standard"
        />
      </BaseForm>
    </div>
  );
};
