export const SET_SEMESTERS = 'SET_SEMESTERS';
export const DELETE_SEMESTER = 'DELETE_SEMESTER';
export const CLEAR_SEMESTER = 'CLEAR_SEMESTER';
export const DELETE_COURSE_FROM_SEMESTER = 'DELETE_COURSE_FROM_SEMESTER';
export const UPDATE_LETTER_GRADE_OF_COURSE = 'UPDATE_LETTER_GRADE_OF_COURSE';
export const ADD_COURSE_TO_SEMESTER = 'ADD_COURSE_TO_SEMESTER';

export const setSemesters = (semesters) => ({
  type: SET_SEMESTERS,
  payload: semesters,
});

export const removeSemester = (semesterId) => ({
  type: DELETE_SEMESTER,
  payload: { semesterId },
});

export const clearSemesters = () => ({
  type: CLEAR_SEMESTER,
});

export const removeCourseFromSemester = (course, semesterUUID) => ({
  type: DELETE_COURSE_FROM_SEMESTER,
  payload: { course, semesterUUID },
});

export const updateLetterGradeOfCourseInSem = (
  semesterUUID,
  course,
  grade
) => ({
  type: UPDATE_LETTER_GRADE_OF_COURSE,
  payload: {
    semesterUUID,
    course,
    grade,
  },
});

export const addCourseToSemester = (course, semesterUUID) => ({
  type: ADD_COURSE_TO_SEMESTER,
  payload: {
    semesterUUID,
    course,
  },
});
