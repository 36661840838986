import React, { Fragment, useRef, useState } from 'react';
import {
  Dialog,
  DialogBackdrop,
  DialogTitle,
  Transition,
} from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import { axiosInstance, getUserId } from '../axiosApi';
import ButtonLoader from './ButtonLoader';
import BaseSelect from './BaseSelect';
import LoadingSpinner from './LoadingSpinner';
import { TimerContext } from './Contexts/TimerContextProvider';

const courseIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="lightpink"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    class="inline mr-1 w-6 h-5"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
    />
  </svg>
);

const componentIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="salmon"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    class="inline mr-1 w-6 h-5"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 0 1-.657.643 48.39 48.39 0 0 1-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 0 1-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 0 0-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 0 1-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 0 0 .657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 0 1-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 0 0 5.427-.63 48.05 48.05 0 0 0 .582-4.717.532.532 0 0 0-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 0 0 .658-.663 48.422 48.422 0 0 0-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 0 1-.61-.58v0Z"
    />
  </svg>
);

const StudyDescriptionDialog = ({ closeModal, onlyDescription = false }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [inputDescription, setInputDescription] = useState('');
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState({});
  const [selectedComponent, setSelectedComponent] = useState({});
  const [selectedMode, setSelectedMode] = useState('stopwatch');
  const [timerHours, setTimerHours] = useState('0');
  const [timerMinutes, setTimerMinutes] = useState('0');
  const [timerSeconds, setTimerSeconds] = useState('0');
  const [showTimerError, setShowTimerError] = useState(false);
  const [isCourseLoading, setIsCourseLoading] = useState(false);
  const { startTimer, setDescription, setForCourse, setForComponent } =
    React.useContext(TimerContext);

  // fetch courses when it loads.
  React.useEffect(() => {
    if (!onlyDescription) {
      fetchCourses();
    }
  }, [onlyDescription]);

  const fetchCourses = async () => {
    setIsCourseLoading(true);
    try {
      const response = await axiosInstance.get(`courses/all/${getUserId()}`);
      const toBeCourses = response.data.courses;

      setCourses(toBeCourses);
      if (toBeCourses.length > 0) {
        setSelectedCourse(toBeCourses[0]);
        if (toBeCourses[0].components.length > 0) {
          setSelectedComponent(toBeCourses[0].components[0]);
        }
      }
      setIsCourseLoading(false);
      return response;
    } catch (error) {
      setIsCourseLoading(false);
      alert(`Error! ${error.message}`);
      throw error;
    }
  };

  const handleStartTimer = () => {
    if (selectedMode === 'timer') {
      if (timerHours === '0' && timerSeconds === '0' && timerMinutes === '0') {
        setShowTimerError(true);
        return;
      } else {
        setShowTimerError(false);
      }
    }

    setDescription(inputDescription);
    if (!onlyDescription) {
      setForCourse(selectedCourse);
      setForComponent(selectedComponent);
    }
    startTimer(
      selectedMode,
      parseInt(timerHours, 10),
      parseInt(timerMinutes, 10),
      parseInt(timerSeconds, 10)
    );
    closeModal();
  };
  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          return closeModal();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="chartreuse"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 10.5v6m3-3H9m4.06-7.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                        />
                      </svg>
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Add {`${!onlyDescription ? 'details' : 'description'}`}{' '}
                        for your study session?
                      </DialogTitle>
                      <h4 className="text-xs text-gray-400">
                        {!onlyDescription
                          ? 'Adding details gives you insight into study breakdown between courses and components.'
                          : 'Descriptions add much more meaning to your study sessions.'}
                      </h4>
                      <label
                        for="description"
                        class="mt-5 block text-sm font-medium leading-6 text-gray-900"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="mistyrose"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="inline mr-1 w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"
                          />
                        </svg>
                        Description (optional)
                      </label>
                      <div class="max-w-xs relative mt-2 rounded-md shadow-sm">
                        <input
                          type="text"
                          name="description"
                          id="study-desc"
                          value={inputDescription}
                          class="block w-full rounded-md border-0 py-1.5 pl-2 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="Reading Chapter 1"
                          onChange={(e) => setInputDescription(e.target.value)}
                        />
                      </div>

                      {isCourseLoading && (
                        <div className="flex justify-center max-w-xs mt-4">
                          <LoadingSpinner />
                        </div>
                      )}
                      {!onlyDescription && !isCourseLoading && (
                        <>
                          <div
                            id="course"
                            class="max-w-xs relative mt-3 rounded-md shadow-sm"
                          >
                            <BaseSelect
                              disabled={courses.length === 0}
                              disabledText="No courses to chose from"
                              labelSvg={courseIcon}
                              selected={selectedCourse}
                              title={'Course (optional)'}
                              options={courses}
                              selectOption={(val) => {
                                setSelectedCourse(val);
                                setSelectedComponent(
                                  val.components ? val.components[0] : {}
                                );
                              }}
                            />
                          </div>
                          {Object.keys(selectedCourse).length !== 0 && (
                            <div
                              id="component"
                              class=" max-w-xs relative mt-3 rounded-md shadow-sm"
                            >
                              <BaseSelect
                                selected={selectedComponent}
                                title={'Component (optional)'}
                                options={selectedCourse.components}
                                disabled={
                                  selectedCourse.components.length === 0
                                }
                                disabledText="No components to chose from"
                                labelSvg={componentIcon}
                                selectOption={(val) => {
                                  setSelectedComponent(val);
                                }}
                              />
                            </div>
                          )}
                        </>
                      )}

                      {!isCourseLoading && (
                        <div class="flex items-center mt-4">
                          <div className="mr-3">
                            <input
                              checked={selectedMode === 'stopwatch'}
                              id="default-radio-1"
                              type="radio"
                              value=""
                              onChange={() => setSelectedMode('stopwatch')}
                              name="default-radio"
                              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500   focus:ring-2 "
                            />
                            <label
                              for="default-radio-1"
                              class="ms-2 text-sm font-medium text-gray-900 "
                            >
                              Stopwatch
                            </label>
                          </div>
                          <div>
                            <input
                              checked={selectedMode === 'timer'}
                              id="default-radio-2"
                              type="radio"
                              value=""
                              onChange={() => setSelectedMode('timer')}
                              name="default-radio"
                              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2 "
                            />
                            <label
                              for="default-radio-2"
                              class="ms-2 text-sm font-medium text-gray-900"
                            >
                              Timer
                            </label>
                          </div>
                        </div>
                      )}

                      {selectedMode === 'timer' && (
                        <div className="flex mt-2 mb-2">
                          {/* Hours */}
                          <div className="flex mt-2 flex-col mr-4">
                            <label
                              htmlFor="hours"
                              className=" text-xs sm:text-sm "
                            >
                              Hours
                            </label>
                            <input
                              type="number"
                              name="hours"
                              id="hours"
                              value={timerHours}
                              min={0}
                              onChange={(e) => {
                                const val = e.target.value;
                                if (
                                  val !== 0 ||
                                  timerMinutes !== 0 ||
                                  timerSeconds !== 0
                                )
                                  setShowTimerError(false);
                                setTimerHours(e.target.value);
                              }}
                              className="rounded-md w-full mt-1  border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              placeholder="00"
                            />
                          </div>

                          {/* Minutes */}
                          <div className="flex flex-col mr-4 mt-2 ">
                            <label
                              htmlFor="minutes"
                              className=" text-xs sm:text-sm "
                            >
                              Minutes
                            </label>
                            <input
                              type="number"
                              name="minutes"
                              id="minutes"
                              min={0}
                              max={60}
                              value={timerMinutes}
                              onChange={(e) => {
                                const val = e.target.value;
                                if (
                                  val !== 0 ||
                                  timerHours !== 0 ||
                                  timerSeconds !== 0
                                )
                                  setShowTimerError(false);
                                setTimerMinutes(e.target.value);
                              }}
                              className="rounded-md w-full mt-1  border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              placeholder="00"
                            />
                          </div>

                          {/* Seconds */}
                          <div className="flex flex-col mt-2">
                            <label
                              htmlFor="seconds"
                              className=" text-xs sm:text-sm "
                            >
                              Seconds
                            </label>
                            <input
                              type="number"
                              name="seconds"
                              id="seconds"
                              min={0}
                              max={60}
                              value={timerSeconds}
                              onChange={(e) => {
                                const val = e.target.value;
                                if (
                                  val !== 0 ||
                                  timerHours !== 0 ||
                                  timerMinutes !== 0
                                )
                                  setShowTimerError(false);
                                setTimerSeconds(e.target.value);
                              }}
                              className="rounded-md w-full mt-1  border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              placeholder="00"
                            />
                          </div>
                        </div>
                      )}
                      {showTimerError && (
                        <span class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                          You need to add time for atleast one of hours, minutes
                          or seconds.
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    disabled={isLoading}
                    className="inline-flex items-center w-full justify-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-400 sm:ml-3 sm:w-auto"
                    onClick={() => handleStartTimer()}
                  >
                    Start timer
                    {isLoading && <ButtonLoader />}
                    {isSuccess && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="ml-1 w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    )}
                  </button>

                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => closeModal()}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default StudyDescriptionDialog;
