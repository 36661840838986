import FeatureSection from './FeatureSection.js';
import Hero from './Hero.js';
import Mission from './Mission.js';
import Testimonial from './Testimonial.js';

const Landing = () => {
  return (
    <div>
      <Hero />
      <FeatureSection />
      {/* <Testimonial /> */}
      <Mission />
    </div>
  );
};

export default Landing;
