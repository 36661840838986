import React, { useState, useEffect } from 'react';
import {
  calcGoalGrade,
  calcGradeReceived,
  calcTotalWeightCompleted,
  calcWeight,
} from './utils';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import { styled } from '@mui/material/styles';
import { axiosInstance, getUserId } from '../../axiosApi';
import { TimerContext } from '../Contexts/TimerContextProvider';

import Modal from '../Modal';

import {
  resetIcon,
  stopIcon,
  resumeIcon,
  startIcon,
  pauseIcon,
  displayFormatTime,
} from '../../utils/timerUtils';

import { formatDate } from '../../utils/dateUtils';
import { EditComponentForm } from './EditComponentForm';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#6366f1',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
    fontWeight: 700,
  },
}));

const initialBlankComponent = [
  {
    name: '',
    weight: '',
    grade_received: '',
    goal_grade: '',
    due_date: '',
  },
];

const CourseTable = ({
  components,
  setComponents,
  setFeedbackAvailable,
  timerInfo,
  setupTimer,
  addTime,
}) => {
  const { timerOn, timerPaused, timers, pauseTimer, resetTimer, resumeTimer } =
    React.useContext(TimerContext);

  const [showEditComponentForm, setShowEditComponentForm] = useState(false);
  const [showDeleteComponentModal, setShowDeleteComponentModal] =
    useState(false);
  const [toBeEditedComponent, setToBeEditedComponent] = useState(null);
  const [toBeDeletedComponent, setToBeDeletedComponent] = useState(null);

  const cancelDeleteComponent = () => {
    setShowDeleteComponentModal(false);
    setToBeDeletedComponent(null);
  };
  // refactor to utils
  const sortArrByDate = (comps) => {
    comps.sort(function (a, b) {
      return new Date(a.due_date) - new Date(b.due_date);
    });
    return comps;
  };

  const deleteComponentHandler = async () => {
    try {
      const data = {
        id: toBeDeletedComponent.uuid,
        user_id: getUserId(),
      };
      const response = await axiosInstance.delete('courses/components/', {
        data,
      });

      setComponents((prevComponents) => {
        let result = [];
        for (let component of prevComponents) {
          if (component.uuid === toBeDeletedComponent.uuid) {
            continue;
          }
          result.push(component);
        }
        // sort the components by date
        return sortArrByDate(result);
      });
      setShowDeleteComponentModal(false);
      setToBeDeletedComponent(null);

      return response;
    } catch (error) {
      alert(`Error! ${error.message}`);
      throw error;
    }
  };

  const openEditComponentForm = (component) => {
    setToBeEditedComponent(component, () => {});
    setShowEditComponentForm(true);
  };

  const calcTotalTimeStudied = () => {
    let totalHours = 0;
    let totalMins = 0;
    let totalSeconds = 0;
    for (let component of components) {
      totalHours += component.total_time_studied_hours;
      totalMins += component.total_time_studied_minutes;
      totalSeconds += component.total_time_studied_seconds;
    }

    if (totalSeconds >= 60) {
      totalMins += Math.floor(totalSeconds / 60);
      totalSeconds %= 60;
    }

    // Handle overflow from minutes to hours
    if (totalMins >= 60) {
      totalHours += Math.floor(totalMins / 60);
      totalMins %= 60;
    }

    let formattedTime = '';

    if (totalHours > 0) {
      formattedTime += `${totalHours} Hr `;
    }

    if (totalMins > 0) {
      formattedTime += `${totalMins}min `;
    }

    if (totalSeconds > 0 || formattedTime === '') {
      formattedTime += `${totalSeconds} secs`;
    }

    return formattedTime.trim();
  };

  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{ width: '100%', margin: 'auto', marginBottom: '20px' }}
      >
        <Table stickyHeader sx={{}} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Component</StyledTableCell>
              <StyledTableCell align="center">Weight (%)</StyledTableCell>
              <StyledTableCell align="center">
                Completed Weight (%)
              </StyledTableCell>
              <StyledTableCell align="center">
                Grade Received&nbsp;(%)
              </StyledTableCell>
              <StyledTableCell align="center">
                Goal Grade&nbsp;(%)
              </StyledTableCell>
              <StyledTableCell align="center">Due Date</StyledTableCell>
              <StyledTableCell align="center">
                Time Studied (HH:MM:SS)
              </StyledTableCell>
              <StyledTableCell align="center">
                Elapsed Time (HH:MM:SS)
              </StyledTableCell>
              <StyledTableCell sx={{ width: '10rem' }} align="center">
                Timer Actions
              </StyledTableCell>
              <StyledTableCell align="center">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {components.map((component, idx) => (
              <StyledTableRow
                key={idx}
                sx={{
                  maxWidth: '100px',
                  whiteSpace: 'nowrap',
                  color: timerInfo.timeColor,
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <StyledTableCell component="th" scope="row">
                  {component.name}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {component.weight}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {component.grade_received === -1 ? (
                    '-'
                  ) : (
                    <DoneIcon style={{ color: 'green' }}></DoneIcon>
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {component.grade_received === -1
                    ? '-'
                    : component.grade_received}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {component.goal_grade === -1 ? '-' : component.goal_grade}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {formatDate(component.due_date)}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {displayFormatTime(
                    component.total_time_studied_hours,
                    component.total_time_studied_minutes,
                    component.total_time_studied_seconds
                  )}
                  {/* {timers[component.uuid] ? time : '-'} */}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    minWidth: '20 em',
                    whiteSpace: 'nowrap',
                    color: timerInfo.timeColor,
                  }}
                >
                  {timers[component.uuid]
                    ? `${String(timerInfo.hours).padStart(2, '0')}:${String(
                        timerInfo.minutes
                      ).padStart(2, '0')}:${String(timerInfo.seconds).padStart(
                        2,
                        '0'
                      )}`
                    : '-'}
                  {/* {timers[component.uuid] ? time : '-'} */}
                </StyledTableCell>

                <StyledTableCell align="center" sx={{ color: '#4f46e5' }}>
                  <div
                    className={
                      (timerOn || timerPaused) && !timers[component.uuid]
                        ? 'disabled-buttons'
                        : ''
                    }
                  >
                    {timerOn && !timerPaused && (
                      <button
                        onClick={pauseTimer}
                        disabled={timerOn && !timers[component.uuid]}
                      >
                        {pauseIcon}
                      </button>
                    )}
                    {!timerPaused && !timerOn && (
                      <button
                        onClick={() => setupTimer(component)} // From prop
                        disabled={timerOn && !timers[component.uuid]}
                      >
                        {startIcon}
                      </button>
                    )}
                    {timerPaused && (
                      <button
                        onClick={() => {
                          resumeTimer(component);
                        }}
                        disabled={timerOn && !timers[component.uuid]}
                      >
                        {resumeIcon}
                      </button>
                    )}
                    {(timerOn || timerPaused) && (
                      <span>
                        <button
                          onClick={addTime}
                          disabled={timerOn && !timers[component.uuid]}
                        >
                          {stopIcon}
                        </button>
                        <button onClick={resetTimer}>{resetIcon}</button>
                      </span>
                    )}
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <button onClick={() => openEditComponentForm(component)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="mr-2 w-5 h-7"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                      />
                    </svg>
                  </button>

                  <button
                    onClick={() => {
                      setToBeDeletedComponent(component);
                      setShowDeleteComponentModal(true);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-7"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </button>
                </StyledTableCell>
              </StyledTableRow>
            ))}

            {components.length === 0 &&
              initialBlankComponent.map((component, idx) => (
                <StyledTableRow
                  key={idx}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <StyledTableCell component="th" scope="row">
                    -
                  </StyledTableCell>
                  <StyledTableCell align="center">-</StyledTableCell>
                  <StyledTableCell align="center">-</StyledTableCell>
                  <StyledTableCell align="center">-</StyledTableCell>
                  <StyledTableCell align="center">-</StyledTableCell>
                  <StyledTableCell align="center">-</StyledTableCell>
                  <StyledTableCell align="center">-</StyledTableCell>

                  <StyledTableCell align="center">-</StyledTableCell>
                  <StyledTableCell align="center" sx={{ color: 'disabled' }}>
                    <button
                      disabled
                      className=" disabled:text-slate-400"
                      onClick={() => {}}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="mr-2 w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                        />
                      </svg>
                    </button>
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <EditIcon
                      sx={{ cursor: 'pointer' }}
                      // onClick={() => console.log("")}
                      color="disabled"
                    />
                    <DeleteIcon
                      sx={{ cursor: 'pointer' }}
                      // onClick={() => console.log("")}
                      color="disabled"
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}

            {components.length > 0 && (
              <StyledTableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <StyledTableCell component="th" scope="row">
                  Total
                </StyledTableCell>
                <StyledTableCell align="center">
                  {calcWeight(components).toFixed(2)}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {calcTotalWeightCompleted(components)}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {calcGradeReceived(components).toFixed(2)}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {calcGoalGrade(components).toFixed(2)}
                </StyledTableCell>
                <StyledTableCell align="center">N/A</StyledTableCell>
                <StyledTableCell align="center">
                  {calcTotalTimeStudied(components)}
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {showDeleteComponentModal && (
        <Modal
          timer={true}
          dialogTitle="Delete Component"
          dialogMessage={`Are you sure you want to delete
                    ${toBeDeletedComponent?.name}?`}
          dialogConfirmMessage="Delete"
          dialogConfirmAction={deleteComponentHandler}
          dialogCancelAction={cancelDeleteComponent}
        />
      )}

      {showEditComponentForm && (
        <EditComponentForm
          isOpen={showEditComponentForm}
          handleClose={() => setShowEditComponentForm(false)}
          componentToBeEdited={toBeEditedComponent}
          components={components}
          setComponents={setComponents}
          setFeedbackAvailable={setFeedbackAvailable}
        />
      )}
    </div>
  );
};

export default CourseTable;
