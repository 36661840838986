import { useState } from 'react';
import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
const PLACEHOLDER_TEXT = 'Select a course';

export default function BaseSelect({
  selected,
  title,
  options,
  selectOption,
  disabled = false,
  disabledText = '',
  labelSvg = '',
}) {
  return (
    <Listbox disabled={disabled} selected={selected} onChange={selectOption}>
      {({ open }) => (
        <>
          <Label className="block text-sm font-medium leading-6 text-gray-900">
            {labelSvg}
            {title}
          </Label>
          <div className="max-w-10 relative mt-2">
            <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
              <span className="flex items-center">
                {disabled && (
                  <span className="text-gray-400 block truncate">
                    {disabledText}
                  </span>
                )}
                {!disabled && (
                  <span className=" block truncate">{selected.name}</span>
                )}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ExpandMoreIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>

            <ListboxOptions
              transition
              className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
            >
              {options.map((option) => (
                <ListboxOption
                  key={option.uuid}
                  className={({ focus }) =>
                    classNames(
                      focus ? 'bg-indigo-600 text-white' : '',
                      !focus ? 'text-gray-900' : '',
                      'relative cursor-default select-none py-2 pl-3 pr-9'
                    )
                  }
                  value={option}
                >
                  {({ selected, focus }) => (
                    <>
                      <div className="flex items-center">
                        {/* <img
                          src={option.avatar}
                          alt=""
                          className="h-5 w-5 flex-shrink-0 rounded-full"
                        /> */}
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate'
                          )}
                        >
                          {option.name}
                        </span>
                      </div>

                      {selected ? (
                        <span
                          className={classNames(
                            focus ? 'text-white' : 'text-indigo-600',
                            'absolute inset-y-0 right-0 flex items-center pr-4'
                          )}
                        >
                          <CheckIcon />
                        </span>
                      ) : null}
                    </>
                  )}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </div>
        </>
      )}
    </Listbox>
  );
}
