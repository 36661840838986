import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { axiosInstance } from '../axiosApi';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  setCustomGradeSettings,
  setCustomGradeSettingsObj,
} from '../redux/actions/customGradeSettingsActions';
import { setSemesters } from '../redux/actions/semesterActions';
import { convertToList, sortSems } from './util';

const GradePointSettings = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isLetterGradeEmpty, setIsLetterGradeEmpty] = useState(false);
  const [isNewLetterGradePointEmpty, setIsNewLetterGradePointEmpty] =
    useState(false);
  const [newLetterGrade, setNewLetterGrade] = useState({
    letterGrade: '',
    gradePoint: '',
  });

  const history = useHistory();

  const dispatch = useDispatch();

  const semesters = useSelector((state) => {
    return state.semesterReducer.semesters;
  });

  const customGradeSettings = useSelector((state) => {
    return state.customGradeSettingsReducer.customGradeSettings;
  });
  const customGradeSettingsObj = useSelector((state) => {
    return state.customGradeSettingsReducer.customGradeSettingsObj;
  });

  const getUserId = () => localStorage.getItem('user_id');

  const fetchSemesters = async () => {
    try {
      //console.log(`user id = ${getUserId()}`);'
      // debugger;
      const response = await axiosInstance.get(`semesters/${getUserId()}`, {
        user_id: getUserId,
      });

      let sortedSems = sortSems(response.data.semesters);
      dispatch(setSemesters(sortedSems));

      return response;
    } catch (error) {
      alert(`Error! ${error.message}`);
      //console.log(error);
      throw error;
    }
  };

  const fetchGradePointSettings = async () => {
    try {
      const response = await axiosInstance.get(
        `user/gradePointSettings/${getUserId()}`
      );
      const data = response.data;
      dispatch(
        setCustomGradeSettings(
          convertToList(data.customGradeSettings.custom_grade_scale)
        )
      );
      dispatch(
        setCustomGradeSettingsObj(data.customGradeSettings.custom_grade_scale)
      );
      return response;
    } catch (error) {
      alert(`Error! ${error.message}`);
      throw error;
    }
  };

  const handleAddGradePoint = () => {
    if (
      letterGradeAlreadyExists(newLetterGrade.letterGrade) ||
      newLetterGrade.letterGrade === '' ||
      newLetterGrade.gradePoint === ''
    ) {
      if (newLetterGrade.letterGrade === '') {
        setIsLetterGradeEmpty(true);
      }
      if (newLetterGrade.gradePoint === '') {
        setIsNewLetterGradePointEmpty(true);
      }
      return;
    }

    addGradePoint();
  };

  const handleEditGradePoint = () => {
    if (newLetterGrade.letterGrade === '' || newLetterGrade.gradePoint === '') {
      return;
    }

    addGradePoint();
  };
  const addGradePoint = async () => {
    try {
      const response = await axiosInstance.put(
        `user/gradePointSettings/${getUserId()}`,
        {
          letterGrade: newLetterGrade.letterGrade,
          gradePoint: newLetterGrade.gradePoint,
        }
      );
      dispatch(
        setCustomGradeSettings(convertToList(response.data.custom_grade_scale))
      );
      dispatch(setCustomGradeSettingsObj(response.data.custom_grade_scale));
      setDialogOpen(false);
      setNewLetterGrade({ letterGrade: '', gradePoint: '' });

      props.showSuccessAlert();
      setIsEditing(false);
      return response;
    } catch (error) {
      alert(`Error! ${error.message}`);
      throw error;
    }
  };

  useEffect(() => {
    if (semesters.length === 0) {
      fetchSemesters();
    }
    if (customGradeSettings.length === 0) {
      fetchGradePointSettings();
    }
  }, []);

  const gradePointHandler = (event) => {
    if (event.target.value === '') {
      setNewLetterGrade((prev) => {
        return { ...prev, gradePoint: event.target.value };
      });
      setIsNewLetterGradePointEmpty(true);
    } else {
      setNewLetterGrade((prev) => {
        return { ...prev, gradePoint: parseFloat(event.target.value) };
      });
      setIsNewLetterGradePointEmpty(false);
    }
  };

  const openEditDialog = (letterGrade, gradePoint) => {
    setNewLetterGrade({ letterGrade, gradePoint });
    setIsEditing(true);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setNewLetterGrade({
      letterGrade: '',
      gradePoint: '',
    });

    setIsEditing(false);
    setDialogOpen(false);
  };

  const letterGradeAlreadyExists = (letterGrade) => {
    if (letterGrade in customGradeSettingsObj) {
      return true;
    }
    return false;
  };

  const letterGradeHandler = (event) => {
    if (event.target.value === '') setIsLetterGradeEmpty(true);
    else setIsLetterGradeEmpty(false);

    setNewLetterGrade((prev) => {
      return { ...prev, letterGrade: event.target.value };
    });
  };

  const letterGradeFocusHandler = (event) => {
    if (event.target.value === '') setIsLetterGradeEmpty(true);
    else setIsLetterGradeEmpty(false);
  };

  const letterGradeInUse = (letterGrade) => {
    for (const semester of semesters) {
      for (const course of semester.courses) {
        const grade = course.letterGrade;
        if (course.letterGrade.toLowerCase() === letterGrade.toLowerCase()) {
          return true;
        }
      }
    }
    return false;
  };

  const deleteGradePointRow = async (letterGrade) => {
    // check if its in use
    if (letterGradeInUse(letterGrade)) {
      props.showErrorAlert(
        'Cannot delete because this letter grade is in use by a course!'
      );

      return;
    }
    try {
      const data = {
        letterGrade: letterGrade,
      };
      const response = await axiosInstance.delete(
        `user/gradePointSettings/${getUserId()}`,
        { data }
      );
      dispatch(
        setCustomGradeSettings(convertToList(response.data.custom_grade_scale))
      );
      dispatch(setCustomGradeSettingsObj(response.data.custom_grade_scale));
      setDialogOpen(false);
      setNewLetterGrade({ letterGrade: '', gradePoint: '' });
      props.showSuccessAlert();
      return response;
    } catch (error) {
      alert(`Error! ${error.message}`);
      //console.log(error);
      throw error;
    }
  };

  let tableRows = [];

  if (customGradeSettings !== undefined) {
    tableRows = customGradeSettings.map(([letterGrade, gradePoint], idx) => (
      <tr className="border-b" key={idx}>
        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">
          {letterGrade}
        </th>
        <td className="px-6 py-4">{gradePoint}</td>
        <td className="px-6 py-4 text-right">
          <EditIcon
            sx={{ cursor: 'pointer' }}
            onClick={() => openEditDialog(letterGrade, gradePoint)}
          />
          <DeleteIcon
            sx={{ cursor: 'pointer' }}
            onClick={() => deleteGradePointRow(letterGrade)}
          />
        </td>
      </tr>
    ));
  }

  return (
    <>
      <button
        type="submit"
        onClick={() => setDialogOpen(true)}
        className="mt-2 mb-5 sm:mb-10 flex w-fit items-center justify-center rounded-md bg-indigo-600 px-3 py-1 sm:py-1.5 text-xs  font-semibold leading-4 sm:leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 md:leading-6 md:text-base md:h-10 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Add letter grade
      </button>
      <div class="flex justify-center relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase   dark:text-gray-400">
            <tr>
              <th scope="col" class="px-3 py-3">
                Letter grade
              </th>
              <th scope="col" class="px-3 py-3">
                Grade point
              </th>
              <th scope="col" class="px-3 py-3">
                <span class="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </table>
      </div>
      <div id="btn-group" className="mt-10 mb-10 flex justify-end">
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={() => history.push('/home')}
        >
          Go home
        </button>
      </div>
      <Dialog open={dialogOpen} onClose={() => console.log('HI')}>
        <DialogTitle>
          {' '}
          {isEditing ? 'Edit the component' : 'Add a component'}
        </DialogTitle>
        <DialogContent>
          <TextField
            error={
              (!isEditing &&
                letterGradeAlreadyExists(newLetterGrade.letterGrade)) ||
              isLetterGradeEmpty
            }
            helperText={
              !isEditing && letterGradeAlreadyExists(newLetterGrade.letterGrade)
                ? 'This letter grade already exists'
                : 'Letter grade cannot be empty'
            }
            margin="dense"
            id="Letter Grade"
            label="Letter Grade"
            placeholder="A"
            type="text"
            value={newLetterGrade.letterGrade}
            fullWidth
            onChange={letterGradeHandler}
            onFocus={letterGradeFocusHandler}
            variant="standard"
          />

          <TextField
            error={isNewLetterGradePointEmpty}
            helperText={'Grade point cannot be empty'}
            margin="dense"
            id="Grade point"
            label="Grade point"
            type="number"
            step={1.0}
            value={
              newLetterGrade.gradePoint === -1 ? '' : newLetterGrade.gradePoint
            }
            placeholder="4.0"
            fullWidth
            onChange={gradePointHandler}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={isEditing ? handleEditGradePoint : handleAddGradePoint}
          >
            {' '}
            {isEditing ? 'Confirm' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GradePointSettings;
