export const SET_CUSTOM_GRADE_SETTINGS = 'SET_CUSTOM_GRADE_SETTINGS';
export const CLEAR_CUSTOM_GRADE_SETTINGS = 'CLEAR_CUSTOM_GRADE_SETTINGS';
export const SET_CUSTOM_GRADE_SETTINGS_OBJ = 'SET_CUSTOM_GRADE_SETTINGS_OBJ';

export const setCustomGradeSettings = (customGradeSettings) => ({
  type: SET_CUSTOM_GRADE_SETTINGS,
  payload: { customGradeSettings },
});

export const setCustomGradeSettingsObj = (customGradeSettingsObj) => ({
  type: SET_CUSTOM_GRADE_SETTINGS_OBJ,
  payload: { customGradeSettingsObj },
});

export const clearCustomGradeSettings = () => ({
  type: CLEAR_CUSTOM_GRADE_SETTINGS,
});
