import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { axiosInstance } from '../axiosApi';
import { TimerContext } from './Contexts/TimerContextProvider';

const StatsDatePicker = ({ fetchStatsForDay }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dates, setDates] = useState();
  const { persisted, setPersisted } = React.useContext(TimerContext);

  const currentDate = new Date();

  const getUserId = () => localStorage.getItem('user_id');

  const fetchAvailableDates = async () => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await axiosInstance.get(`availableStatDates/`, {
      params: {
        user_id: getUserId(),
        timezone: userTimeZone,
      },
    });

    const availableDates = response.data.availableDates.map((dateStr) => {
      const dateParts = dateStr.split('-');
      const year = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10) - 1; // Months in JavaScript are 0-indexed (0 for January)
      const day = parseInt(dateParts[2], 10);

      return new Date(year, month, day);
    });

    setDates(availableDates);
  };

  useEffect(() => {
    fetchStatsForDay(currentDate);
    fetchAvailableDates();
  }, []);

  useEffect(() => {
    if (persisted) {
      fetchStatsForDay(currentDate);
      setPersisted(false);
    }
  }, [persisted]);
  function subtractDays(date, days) {
    const result = new Date(date);
    result.setDate(date.getDate() - days);
    return result;
  }

  return (
    <div className="flex  items-center  mb-10 relative max-w-xs">
      <DatePicker
        selected={selectedDate}
        onChange={(date) => {
          setSelectedDate(date);
          fetchStatsForDay(date);
        }}
        includeDates={dates}
        className="cursor-pointer font-semibold bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  w-auto p-2.5"
        placeholderText="Select date"
        dateFormat="MMM dd, yyyy"
      />
      {selectedDate.getDate() === new Date().getDate() && (
        <h2 className="font-bold  text-xs sm:text-sm ml-2 ">TODAY</h2>
      )}
    </div>
  );
};

export default StatsDatePicker;
