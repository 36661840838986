import React, { useEffect, useState } from 'react';
import ApexCharts from 'apexcharts';

const HOURS = 'Hours';
const MINUTES = 'Minutes';
const SECONDS = 'Seconds';
const TrajectoryGraph = ({ dailyStats, formattedDates }) => {
  const [timeStudiedArr, setTimeStudiedArr] = useState([]);
  const [metricType, setMetricType] = useState([]);
  const [totalTimeStudied, setTotalTimeStudied] = useState(0);

  useEffect(() => {
    const formattedPerDayStats = {};
    let metType = '';
    for (const idx in dailyStats) {
      let curDate = formattedDates[idx];
      let dailyStat = dailyStats[idx];
      let curDayStudyTime = 0;
      formattedPerDayStats[curDate] = {
        HOURS: 0,
        MINUTES: 0,
        SECONDS: 0,
      };

      for (const studyRecord of dailyStat) {
        curDayStudyTime = studyRecord.time_studied;
        formattedPerDayStats[curDate].SECONDS += curDayStudyTime;
        formattedPerDayStats[curDate].MINUTES += curDayStudyTime / 60;
        formattedPerDayStats[curDate].HOURS += curDayStudyTime / 3600;
      }

      //Round to two decimal places
      formattedPerDayStats[curDate].MINUTES = roundToDecimalPlaces(
        formattedPerDayStats[curDate].MINUTES,
        2
      );
      formattedPerDayStats[curDate].HOURS = roundToDecimalPlaces(
        formattedPerDayStats[curDate].HOURS,
        2
      );

      //compute metric type to display
      if (formattedPerDayStats[curDate].SECONDS >= 3600) {
        metType = HOURS;
      } else if (
        formattedPerDayStats[curDate].SECONDS >= 60 &&
        metType !== HOURS
      ) {
        metType = MINUTES;
      } else if (
        formattedPerDayStats[curDate].SECONDS < 60 &&
        metType !== MINUTES &&
        metType !== HOURS
      ) {
        metType = SECONDS;
      }
    }

    const adjustedTime = adjustTimeWithMetric(
      Object.values(formattedPerDayStats),
      metType
    );

    const totalTime = adjustedTime.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

    setTotalTimeStudied(roundToDecimalPlaces(totalTime, 2));
    setTimeStudiedArr(adjustedTime);
    setMetricType(metType);
  }, []);

  function roundToDecimalPlaces(number) {
    return Number(number.toFixed(2));
  }

  const adjustTimeWithMetric = (timeList, metricType) => {
    if (metricType === HOURS) {
      return timeList.map((stat) => {
        return stat.HOURS;
      });
    } else if (metricType === MINUTES) {
      return timeList.map((stat) => {
        return stat.MINUTES;
      });
    } else {
      return timeList.map((stat) => {
        return stat.SECONDS;
      });
    }
  };
  useEffect(() => {
    if (!timeStudiedArr.length > 0 || !metricType) return;
    const options = {
      chart: {
        height: 280,
        width: 300,
        maxWidth: '150  %',
        type: 'area',
        fontFamily: 'Inter, sans-serif',
        dropShadow: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      tooltip: {
        enabled: true,
        x: {
          show: false,
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0.55,
          opacityTo: 0,
          shade: '#1C64F2',
          gradientToColors: ['#1C64F2'],
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 6,
      },
      grid: {
        show: false,
        strokeDashArray: 4,
        padding: {
          left: 2,
          right: 2,
          top: 0,
        },
      },
      series: [
        {
          name: `${metricType} Studied`,
          data: timeStudiedArr,
          color: '#1A56DB',
        },
      ],
      xaxis: {
        categories: formattedDates,
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
      },
    };

    if (
      timeStudiedArr.length > 0 &&
      document.getElementById('area-chart') &&
      typeof ApexCharts !== 'undefined'
    ) {
      const chart = new ApexCharts(
        document.getElementById('area-chart'),
        options
      );
      chart.render();
    }
  }, [timeStudiedArr, metricType]);

  return (
    <div className="mt-2 sm:ml-10 max-w-sm w-full bg-white rounded-lg shadow p-4 md:p-6">
      <div className="flex justify-between">
        <div>
          <h5 className="leading-none text-2xl sm:text-3xl font-bold text-gray-900  pb-2">
            {totalTimeStudied}
          </h5>
          <p className=" text-sm sm:text-base font-normal text-gray-500">
            {metricType} studied
          </p>
        </div>
      </div>
      <div id="area-chart"></div>
      <div className="grid grid-cols-1 items-center border-gray-200 border-t  justify-between">
        <div className="flex justify-between items-center pt-5">
          {/* Button */}
          <button
            id="dropdownDefaultButton"
            data-dropdown-toggle="lastDaysdropdown"
            data-dropdown-placement="bottom"
            className="text-sm font-medium text-gray-500  hover:text-gray-900 text-center inline-flex items-center"
            type="button"
          >
            Last 7 days
            {/* <svg
              className="w-2.5 m-2.5 ml-1.5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 4 4 4-4"
              />
            </svg> */}
          </button>
          {/* Dropdown menu */}
          <div className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
            <ul
              className="py-2 text-sm text-gray-700 "
              aria-labelledby="dropdownDefaultButton"
            >
              <li>
                <a href="#" className="block px-4 py-2 hover:bg-gray-100 ">
                  Yesterday
                </a>
              </li>
              <li>
                <a href="#" className="block px-4 py-2 hover-bg-gray-100 ">
                  Today
                </a>
              </li>
              <li>
                <a href="#" className="block px-4 py-2 hover-bg-gray-100 ">
                  Last 7 days
                </a>
              </li>
              <li>
                <a href="#" className="block px-4 py-2 hover-bg-gray-100 ">
                  Last 30 days
                </a>
              </li>
              <li>
                <a href="#" className="block px-4 py-2 hover-bg-gray-100 ">
                  Last 90 days
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrajectoryGraph;
